import { yupResolver } from '@hookform/resolvers/yup'
import { Alert } from '@mui/material'
import { redirect } from 'components/routes/config'
import { CustomCard, CustomText, Flex, TextField } from 'components/shared'
import ButtonCustom from 'components/shared/Button/Button'
import useDevice from 'hooks/useDevice'
import { InitPaymentType, initPayment, initPaymentSchema } from 'hooks/usePayments'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { FontSize } from 'styles'

export default function InitPayments() {
  const [serverError, setServerError] = useState<string | null>(null)

  const navigate = useNavigate()
  const { isMobile } = useDevice()

  const { control, handleSubmit, formState } = useForm<InitPaymentType>({
    resolver: yupResolver(initPaymentSchema),
  })

  const isSubmittedSuccessfully = formState.isSubmitted && formState.isSubmitSuccessful

  async function onSubmit(values: InitPaymentType) {
    setServerError(null)

    try {
      await initPayment(values)
    } catch (error) {
      setServerError('Algo falló, por favor intente nuevamente en unos minutos.')

      throw error
    }
  }

  return (
    <Flex styles={{ gap: 20, padding: isMobile ? '0' : '0 30%' }}>
      <ButtonCustom text='Atrás' onClick={() => navigate(redirect.home())} />
      {!isSubmittedSuccessfully && (
        <CustomCard size='big' style={{ padding: 40 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex styles={{ gap: 20 }}>
              <CustomText fontSize={FontSize.xxlarge}>Iniciar pago en pesos</CustomText>
              <TextField
                control={control}
                name='email'
                textFieldProps={{
                  placeholder: 'Ingrese Email',
                  fullWidth: true,
                  error: !!formState.errors.email,
                  helperText: formState.errors.email?.message,
                }}
              />
              <Flex row styles={{ gap: 20, flexWrap: 'wrap' }}>
                <ButtonCustom
                  text='Iniciar pago'
                  type='submit'
                  disabled={formState.isSubmitting}
                  isLoading={formState.isSubmitting}
                />
              </Flex>
            </Flex>
          </form>
        </CustomCard>
      )}
      {isSubmittedSuccessfully && <Alert severity='success'>Pago iniciado exitosamente!</Alert>}
      {serverError && <Alert severity='error'>{serverError}</Alert>}
    </Flex>
  )
}
