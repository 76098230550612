// Components
import { Alert, Snackbar } from '@mui/material'
import ProductCard from 'components/business/ProductCard/ProductCard'
import { redirect } from 'components/routes/config'
import { CustomCard, CustomText, Flex, Loading, Modal } from 'components/shared'
import ButtonCustom from 'components/shared/Button/Button'
import useDevice from 'hooks/useDevice'
import { useProductList } from 'hooks/useProductList'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Colors, FontSize, FontWeight } from 'styles'
import { ProductStatus } from '../SellProduct/SellProduct'
import { useProductListStatus } from 'hooks/useProductListStatus'
import QRCode from 'react-qr-code'
import { envKeys } from 'config'

const ListProductsStatus: React.FC = () => {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [linkClaim, setLinkClaim] = useState('')
  const [finishedProducts, setFinishedProducts] = useState([])
  const { isLoading: loadingProducts, data: productsList } = useProductList({})
  const { isLoading: loadingListing, data: listing } = useProductListStatus({})
  const [loading, setLoading] = useState(false)

  const { isMobile } = useDevice()
  const navigate = useNavigate()

  useEffect(() => {
    if (listing?.length > 0 && productsList?.length > 0) {
      const finishedP = listing.filter(
        (p: Product) => p.status === ProductStatus.sold || p.status === ProductStatus.cancelled
      )
      const finishedProductsArr = finishedP.map((p: any) => {
        const extraInfoP = productsList.find((p2: Product) => p2.id === p.productId)

        return { ...extraInfoP, ...p }
      })
      setFinishedProducts(finishedProductsArr)
    }
  }, [listing, productsList])

  const fetchExportData = async () => {
    setLoading(true)
    const bearer = `Bearer ${localStorage.getItem('token')}`
    const response = await fetch(`${envKeys.api.url}/export/transactions`, {
      method: 'GET',
      headers: new Headers({ Authorization: bearer }),
    })
    if (!response.ok) {
      console.error(`HTTP error! Status: ${response.status}`)
      setError(true)
      setLoading(false)

      return
    }
    const blob = await response.blob()
    const downloadUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = downloadUrl

    // Extract filename from the Content-Disposition header
    const contentDisposition = response.headers.get('Content-Disposition')
    const filename = contentDisposition?.split('filename=')[1] ?? 'Transactions.xlsx'

    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    setLoading(false)

    return
  }

  if (loadingProducts || loadingListing || loading) {
    return (
      <Flex styles={{ marginTop: '60px' }}>
        <Loading />
      </Flex>
    )
  }
  const totalSell = finishedProducts.reduce((acc: number, p: any) => (acc += p.price), 0)

  return (
    <Flex styles={{ gap: 20 }}>
      <Flex row={true} styles={{ width: '35%', justifyContent: 'space-between' }}>
        <ButtonCustom text='Atras' onClick={() => navigate(redirect.home())} />
        <ButtonCustom text='Export Data' onClick={async () => fetchExportData()} />
      </Flex>
      <CustomCard size='medium' style={{ padding: isMobile ? 10 : 40, background: Colors.extraLightgray }}>
        <Flex styles={{ gap: 20 }}>
          {finishedProducts?.length > 0 && (
            <>
              <CustomText fontSize={FontSize.xxlarge}>Productos Finalizados</CustomText>
              {finishedProducts.map((p: Product) => (
                <ProductCard
                  title={p.name}
                  subtitle={'Price: ' + p.price + ' USDT'}
                  subtitle2={p.soldDate ? `Vendido el ${new Date(p.soldDate!).toLocaleString()}` : ''}
                  subtitle3={p.explorerLink ? 'Clickea para ver el NFT generado' : ''}
                  image={p.uploadedFileUrl}
                  labelButton={p?.mintNft && p.claimNft ? 'Ver link Claim' : undefined}
                  disabledButton={p.claimNftId == undefined}
                  onClickButton={
                    p?.mintNft && p.claimNft && p.claimNftId !== 'claimed'
                      ? () => {
                          setModalOpen(true)
                          setLinkClaim(p.claimNftId!)
                        }
                      : undefined
                  }
                  variant='horizontal'
                  disableHover
                  cardOnclick={p.explorerLink ? () => window.open(p.explorerLink, '_blank') : undefined}
                />
              ))}
              <CustomText fontSize={FontSize.xxlarge}>Total Ventas: {totalSell.toFixed(4)} USDT</CustomText>
            </>
          )}
        </Flex>
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Flex styles={{ padding: '0 30px 30px 30px', gap: 20 }}>
            <CustomText fontSize={FontSize.xlarge} fontWeight={FontWeight.extraBold}>
              Claim NFT Link
            </CustomText>
            <CustomText fontSize={FontSize.regular}>Utilice este link para mintear su nft</CustomText>
            <a href={window.location.origin + '/claim/' + linkClaim} target='_blank' rel='noreferrer'>
              {window.location.origin + '/claim/' + linkClaim}
            </a>
            <QRCode value={window.location.origin + '/claim/' + linkClaim} />
            <Flex row styles={{ gap: 20 }}>
              <ButtonCustom text='Cancelar' onClick={() => setModalOpen(false)} />
            </Flex>
          </Flex>
        </Modal>
      </CustomCard>
      {success && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={success}
          autoHideDuration={2000}
          onClose={() => setSuccess(false)}
        >
          <Alert
            onClose={() => {
              setSuccess(false)
            }}
            severity='success'
          >
            Wallet Updated!
          </Alert>
        </Snackbar>
      )}
      {error && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={error}
          autoHideDuration={2000}
          onClose={() => setError(false)}
        >
          <Alert
            onClose={() => {
              setError(false)
            }}
            severity='error'
          >
            Algo salio mal, intente nuevamente.
          </Alert>
        </Snackbar>
      )}
    </Flex>
  )
}

export default ListProductsStatus
