import { Box, styled } from '@mui/system'
import { Colors } from 'styles/colors'

export const boxMobileButtonStyles = {
  width: '65%',
  marginLeft: '8px',
}

export const boxButtonStyles = {
  width: '100%',
}

export const StyledImg = styled('img')({
  alignSelf: 'center',
  marginBottom: '2%',
})

export const buttonsFlexStyles = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignSelf: 'center',
  marginTop: '2%',
  flexDirection: 'row',
  gap: '16px',
}

export const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 2,
  padding: '8px 16px',
  position: 'absolute',
  minWidth: '81px',
  height: '30px',
  left: '0px',
  top: '0px',
  background: Colors.blackLight,
  borderRadius: '0px 0px 16px 0px',
  color: Colors.green,
})

const getStyles = (isMobile: boolean) => ({
  hover: {
    width: isMobile ? '100%' : undefined,
  },
  root: {
    background: Colors.white,
    padding: isMobile ? 8 : 20,
    maxWidth: isMobile ? '100%' : 340,
    boxShadow: `0px 3px 6px rgba(${Colors.blackLight}, 0.15)`,
    borderRadius: '6px',
    alignItems: isMobile ? 'center' : 'flex-start',
    position: 'relative',
    transition: 'transform .5s',
    flexDirection: isMobile ? 'row' : 'column',
  },
  loading: {
    background: Colors.white,
    padding: isMobile ? 8 : 20,
    minWidth: isMobile ? 220 : 340,
    minHeight: isMobile ? 120 : 433,
  },
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: isMobile ? 400 : undefined,
  },
  image: {
    alignSelf: 'center',
    marginBottom: '2%',
    width: isMobile ? 106 : '100%',
    height: isMobile ? 106 : '100%',
  },
})

export default getStyles
