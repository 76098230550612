import { envKeys } from 'config'
import { useQuery } from 'react-query'
import { HTTPMethod, HttpRequest } from 'utils/apiUtils'

const fetchClaimListing = async (claimId: string) => {
  const request: HttpRequest = {
    method: HTTPMethod.GET,
    headers: new Headers({ 'content-type': 'application/json' }),
  }
  const response = await fetch(`${envKeys.api.url}/listProductsListings/claim?claimId=${claimId}`, request).then(
    (res) => res.json()
  )

  return response
}

const useClaim = ({ enabled = true, claimId }: { enabled?: boolean; claimId: string }) => {
  return useQuery(['claim'], () => fetchClaimListing(claimId), { enabled: enabled })
}

export { useClaim }
