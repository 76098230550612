/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ButtonProps } from '@mui/material'

const WidthSize = {
  none: null,
  small: 100,
  medium: 176,
  large: 216,
}

const HeightSize = {
  none: null,
  small: 40,
  medium: 40,
  large: 48,
}
interface ButtonStylesProps {
  isMobile: boolean
  variant: ButtonProps['variant']
  color: ButtonProps['color']
  size: keyof typeof WidthSize
  disabled: boolean
}

const getStyles = ({ size }: ButtonStylesProps): Styles => ({
  //Containers
  button: {
    gap: 24,
    minWidth: size !== 'none' ? WidthSize[size] : undefined,
    width: size === 'none' ? '100%' : undefined,
    height: HeightSize[size],
    textTransform: 'none',
    fontFamily: 'Clash',
  },
})

export default getStyles
