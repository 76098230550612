// Material
import { Button, ButtonProps, SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
// Shared Components
import { CustomText, Loading } from 'components/shared'
import { CustomTextProps } from 'components/shared/CustomText/CustomText'
// Hooks
import useStyles from 'hooks/useStyles'
import React from 'react'
// Styles
import { FontSize, FontWeight } from 'styles/font'

//Types
import { Colors } from 'styles'
import getStyles from './style'

type IconMaterial = OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & {
  muiName: string
}

export interface CustomButtonProps extends Omit<ButtonProps, 'size'> {
  text?: string
  textProps?: CustomTextProps
  size?: 'none' | 'small' | 'medium' | 'large'
  IconRight?: Icon | IconMaterial
  IconLeft?: Icon | IconMaterial
  loading?: boolean
}

const CustomButton: React.FC<CustomButtonProps> = ({
  text,
  color = 'primary',
  size = 'medium',
  variant = 'contained',
  disabled = false,
  textProps,
  style,
  loading,
  children,
  IconLeft,
  IconRight,
  ...buttonProps
}) => {
  const { BaseStyles } = useStyles({
    getStyles: (isMobile) =>
      getStyles({
        isMobile,
        color,
        variant,
        size,
        disabled,
      }),
  })

  const getLoadingColor = () => {
    if (variant === 'contained') {
      return color === 'primary' ? 'secondary' : 'primary'
    }

    return 'primary'
  }

  const disabledStylesButton = disabled ? { background: Colors.extraExtraLightgray, color: Colors.white } : {}

  return (
    <Button
      variant={variant}
      disabled={disabled || loading}
      disableElevation
      style={{ ...BaseStyles.button, ...disabledStylesButton, ...style }}
      {...buttonProps}
    >
      {loading ? (
        <Loading color={getLoadingColor()} />
      ) : (
        <>
          {IconLeft && <IconLeft />}
          {text && (
            <CustomText
              style={{ color: '#FFFFFD' }}
              fontWeight={FontWeight.medium}
              fontSize={FontSize.large}
              {...textProps}
            >
              {text}
            </CustomText>
          )}
          {children && children}
          {IconRight && <IconRight />}
        </>
      )}
    </Button>
  )
}

export default CustomButton
