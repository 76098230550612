import Router from 'components/routes/Router/Router'
import React from 'react'

// Routes
import { routes } from './routes'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CollectionsProps {}

const Collections: React.FC<CollectionsProps> = () => {
  return <Router routes={routes} />
}

export default Collections
