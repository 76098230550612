import React from 'react'
// Material UI
import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@mui/system'
import EmptyStateCard from 'components/business/EmptyStateCard'
import { redirect } from 'components/routes/config'
import { CustomText } from 'components/shared'
// Components
// Images
import { useNavigate } from 'react-router-dom'

interface FormProps {}

const line1 = 'Please connect your Google account to start using our services.'

const Rental: React.FC<FormProps> = () => {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth0()

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate(redirect.home())
    }
  }, [isAuthenticated, navigate])

  return (
    <Box>
      <EmptyStateCard variant='vertical' title='Welcome!' FooterComponent={() => <CustomText>{line1}</CustomText>} />
    </Box>
  )
}

export default Rental
