// Components
import { Alert, Snackbar, TextField } from '@mui/material'
import { redirect } from 'components/routes/config'
import { CustomCard, CustomText, Flex, Loading, Modal } from 'components/shared'
import ButtonCustom from 'components/shared/Button/Button'
import { envKeys } from 'config'
import { useAdminWallet } from 'hooks/useAdminWallet'
import useDevice from 'hooks/useDevice'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FontSize, FontWeight } from 'styles'
import { HTTPMethod } from 'utils/apiUtils'

const WalletAdmin: React.FC = () => {
  const navigate = useNavigate()
  const { isLoading, data } = useAdminWallet({})
  const { isMobile } = useDevice()
  const [wallet] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const postAdminWallet = async () => {
    const bearer = `Bearer ${localStorage.getItem('token')}`
    fetch(`${envKeys.api.url}/admin/walletFee`, {
      method: HTTPMethod.PUT,
      headers: new Headers({ 'content-type': 'application/json', Authorization: bearer }),
      body: JSON.stringify({ wallet }),
    })
      .then((resp) => resp.json())
      .then((res) => {
        if (res?.stack?.includes('Error')) {
          throw Error(res.message)
        }
        setSuccess(true)
        setTimeout(() => window.history.back(), 3000)
      })
      .catch((err) => {
        setError(true)
      })
  }

  if (isLoading) {
    return (
      <Flex styles={{ marginTop: '60px' }}>
        <Loading />
      </Flex>
    )
  }

  return (
    <Flex styles={{ gap: 20 }}>
      <CustomCard size='medium' style={{ padding: isMobile ? 10 : 40 }}>
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Flex styles={{ padding: '0 30px 30px 30px', gap: 20 }}>
            <CustomText fontSize={FontSize.xlarge} fontWeight={FontWeight.extraBold}>
              Actualizar Wallet
            </CustomText>
            <CustomText fontSize={FontSize.regular}>Compruebe que la wallet es correcta, desea continuar?</CustomText>
            <CustomText fontSize={FontSize.regular} fontWeight={FontWeight.bold}>
              {wallet}
            </CustomText>
            <ButtonCustom text={'Actualizar Wallet'} onClick={() => postAdminWallet()} />
          </Flex>
        </Modal>
        <Flex styles={{ gap: 20 }}>
          <CustomText fontSize={FontSize.xxlarge} fontWeight={FontWeight.bold}>
            Wallet de cobro de fee
          </CustomText>
          <TextField fullWidth disabled value={data?.wallet} helperText='Saved wallet' />
          {/* TODO: Review restrictions to implement change wallet admin
          
          <TextField fullWidth disabled value={data?.email} helperText='Last change by' />
          <TextField
            fullWidth
            onChange={e=>setWallet(e.target.value)}
            error={validWallet} 
            helperText={validWallet ? 'Incorrect address format' : ''}
            value={wallet}
          />
          <ButtonCustom text={'Actualizar Wallet'} onClick={()=>setModalOpen(true)} disabled={validWallet}/> */}

          <ButtonCustom text='Atrás' onClick={() => navigate(redirect.admin())} />
        </Flex>
      </CustomCard>
      {success && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={success}
          autoHideDuration={2000}
          onClose={() => setSuccess(false)}
        >
          <Alert
            onClose={() => {
              setSuccess(false)
            }}
            severity='success'
          >
            Wallet Updated!
          </Alert>
        </Snackbar>
      )}
      {error && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={error}
          autoHideDuration={2000}
          onClose={() => setError(false)}
        >
          <Alert
            onClose={() => {
              setError(false)
            }}
            severity='error'
          >
            Algo salio mal, intente nuevamente.
          </Alert>
        </Snackbar>
      )}
    </Flex>
  )
}

export default WalletAdmin
