import React, { useState } from 'react'
// Material UI
import { Box } from '@mui/system'
// Components
// Images
import { useNavigate, useParams } from 'react-router-dom'
// Crypto
import { TextField } from '@mui/material'
import ProductCard from 'components/business/ProductCard/ProductCard'
import { redirect } from 'components/routes/config'
import { CustomCard, CustomText, Flex, Loading } from 'components/shared'
import ButtonCustom from 'components/shared/Button/Button'
import { envKeys } from 'config'
import { useClaim } from 'hooks/useClaim'
import useStepper from 'hooks/useStepper'
import { SuccessLottie } from 'images'
import Lottie from 'lottie-react'
import { FontSize, FontWeight } from 'styles'
import { HTTPMethod } from 'utils/apiUtils'
import { ethAddressRegex } from 'utils/objectUtils'

const Claim: React.FC = () => {
  const { claimId } = useParams()
  const { data: productListing, isLoading } = useClaim({ /*  enabled: !!claimId, */ claimId: claimId! })
  const [wallet, setWallet] = useState('')
  const validWallet = wallet.match(ethAddressRegex) === null
  const [, setSuccess] = useState(false)
  const [productMinted, setProductMinted] = useState<any>()
  const [, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const { currentStep, goToNextStep } = useStepper(1)
  const navigate = useNavigate()

  console.log({ claimId, productListing })

  const postCustomer = async () => {
    setLoading(true)
    const bearer = `Bearer ${localStorage.getItem('token')}`
    fetch(`${envKeys.api.url}/listProductsListings/mint`, {
      method: HTTPMethod.POST,
      headers: new Headers({ 'content-type': 'application/json', Authorization: bearer }),
      body: JSON.stringify({
        productId: productListing.productId,
        toAddress: wallet,
        claimId,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data?.stack?.includes('Error')) {
          throw Error(data.message)
        }
        setSuccess(true)
        setProductMinted(data)
        goToNextStep()
      })
      .catch((err) => setError(true))
      .finally(() => setLoading(false))
  }

  if (isLoading || loading) {
    return (
      <Flex styles={{ marginTop: '60px' }}>
        <Loading />
      </Flex>
    )
  }

  if (!productListing?.name && !isLoading && !productMinted) {
    navigate(redirect.login())
  }

  return (
    <Flex styles={{ gap: 20 }}>
      {currentStep === 0 && (
        <Box>
          <ProductCard
            title={productListing.name}
            subtitle={'Price: ' + productListing.price + ' USDT'}
            subtitle2={'Status: ' + productListing.status}
            subtitle3={`Vendido el ${new Date(productListing.soldDate!).toLocaleString()}`}
            image={productListing.uploadedFileUrl}
            variant='horizontal'
          />
        </Box>
      )}
      <CustomCard size='medium' style={{ padding: 40 }}>
        <Flex styles={{ gap: 20 }}>
          {currentStep === 0 && (
            <>
              <CustomText fontSize={FontSize.xxlarge} fontWeight={FontWeight.bold}>
                Claim NFT
              </CustomText>
              <CustomText fontSize={FontSize.regular}>
                Ingrese direccion a la que desea mintear el nft del producto
              </CustomText>
              <TextField
                fullWidth
                onChange={(e) => setWallet(e.target.value)}
                error={validWallet}
                helperText={validWallet ? 'Incorrect address format' : ''}
              />
              <Flex row styles={{ gap: 20, flexWrap: 'wrap' }}>
                <ButtonCustom text='Mint Nft' onClick={() => postCustomer()} disabled={validWallet} />
              </Flex>
            </>
          )}
          {currentStep === 1 && productMinted && (
            <>
              <CustomText fontSize={FontSize.xxlarge} fontWeight={FontWeight.bold}>
                NFT MINTED!
              </CustomText>
              <Lottie loop animationData={SuccessLottie} />
              <div
                onClick={() => window.open(productMinted.explorerLink, '_blank')}
                style={{ cursor: 'pointer', width: '100%' }}
              >
                <CustomText
                  fontSize={FontSize.regular}
                  style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                >
                  {productMinted.explorerLink}
                </CustomText>
              </div>
            </>
          )}
        </Flex>
      </CustomCard>
    </Flex>
  )
}

export default Claim
