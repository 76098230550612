// Components
import { useAuth0 } from '@auth0/auth0-react'
import { redirect } from 'components/routes/config'
import { CustomCard, Flex, Loading } from 'components/shared'
import ButtonCustom from 'components/shared/Button/Button'
import { useCostumer } from 'hooks/useCostumer'
import { useWhitelist } from 'hooks/useWhitelist'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Form: React.FC = () => {
  const navigate = useNavigate()
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()
  const [isTokenAvailable, setIsTokenAvailable] = useState(!!localStorage.getItem('token'))
  const { isLoading: loadingWhitelist, data: whitelist } = useWhitelist({})
  const { isLoading: loadingApiKey, data: customerData } = useCostumer({})
  const whitelistAccess = whitelist !== null

  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessTokenSilently()
      localStorage.setItem('token', token)
      setIsTokenAvailable(true)
    }
    if (isAuthenticated) {
      getToken()
    }
  }, [getAccessTokenSilently, isAuthenticated])

  if (!isAuthenticated && !isLoading) {
    navigate(redirect.login())
  }

  if (!isLoading && isAuthenticated && !whitelistAccess) {
    navigate(redirect.errorAccess())
  }

  if ((isAuthenticated && !isTokenAvailable) || isLoading || loadingApiKey || loadingWhitelist) {
    return (
      <Flex styles={{ marginTop: '60px' }}>
        <Loading />
      </Flex>
    )
  }

  return (
    <Flex styles={{ gap: 20 }}>
      <CustomCard size='medium' style={{ padding: 40 }}>
        <Flex styles={{ gap: 20 }}>
          <ButtonCustom fullWidth text='Definir Wallet' onClick={() => navigate(redirect.wallet())} />
          <ButtonCustom
            fullWidth
            text='Cargar Productos'
            onClick={() => navigate(redirect.product())}
            disabled={!customerData?.wallet}
          />
          <ButtonCustom
            fullWidth
            text='Vender productos'
            disabled={!customerData?.wallet}
            onClick={() => navigate(redirect.listProduct())}
          />
          <ButtonCustom
            fullWidth
            text='Ventas Activas'
            disabled={!customerData?.wallet}
            onClick={() => navigate(redirect.listProductsActive())}
          />
          <ButtonCustom
            fullWidth
            text='Ventas Finalizadas'
            disabled={!customerData?.wallet}
            onClick={() => navigate(redirect.listProductsStatus())}
          />
          <ButtonCustom
            fullWidth
            text='Ver ApiKey'
            disabled={!customerData?.wallet}
            onClick={() => navigate(redirect.apiKey())}
          />
          {customerData?.payments && (
            <ButtonCustom
              fullWidth
              text='Iniciar pago en pesos'
              disabled={!customerData?.payments}
              onClick={() => navigate(redirect.initPayments())}
            />
          )}
          {customerData?.payments && (
            <ButtonCustom
              fullWidth
              text='Listado de pagos'
              disabled={!customerData?.payments}
              onClick={() => navigate(redirect.paymentsList())}
            />
          )}
          {customerData?.payments && (
            <ButtonCustom
              fullWidth
              text='Actualizar dólar'
              disabled={!customerData?.payments}
              onClick={() => navigate(redirect.updateDollar())}
            />
          )}
        </Flex>
      </CustomCard>
    </Flex>
  )
}

export default Form
