import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Snackbar } from '@mui/material'
import { redirect } from 'components/routes/config'
import { CustomCard, CustomText, Flex, TextField } from 'components/shared'
import ButtonCustom from 'components/shared/Button/Button'
import { useCostumer } from 'hooks/useCostumer'
import useDevice from 'hooks/useDevice'
import { UpdateDollarValueType, updateDollarValue, updateDollarValueSchema } from 'hooks/usePayments'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { FontSize } from 'styles'

export default function UpdateDollar() {
  const { data: customer } = useCostumer({})

  const [serverError, setServerError] = useState<string | null>(null)

  const navigate = useNavigate()
  const { isMobile } = useDevice()

  const { control, handleSubmit, formState, reset, getValues } = useForm<UpdateDollarValueType>({
    resolver: yupResolver(updateDollarValueSchema),
    defaultValues: {
      newDollarValue: customer?.dollarValue,
    },
  })

  const isSubmittedSuccessfully = formState.isSubmitted && formState.isSubmitSuccessful

  async function onSubmit(values: UpdateDollarValueType) {
    setServerError(null)

    try {
      await updateDollarValue(values)
    } catch (error) {
      setServerError('Algo falló, por favor intente nuevamente en unos minutos.')

      throw error
    }
  }

  return (
    <Flex styles={{ gap: 20, padding: isMobile ? '0' : '0 30%' }}>
      <ButtonCustom text='Atrás' onClick={() => navigate(redirect.home())} />
      <CustomCard size='big' style={{ padding: 40 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex styles={{ gap: 20 }}>
            <CustomText fontSize={FontSize.xxlarge}>Actualizar valor del dólar</CustomText>
            <TextField
              control={control}
              name='newDollarValue'
              textFieldProps={{
                placeholder: 'Valor del Dólar',
                fullWidth: true,
                error: !!formState.errors.newDollarValue,
                helperText: formState.errors.newDollarValue?.message,
                //defaultValue: customer?.dollarValue,
              }}
            />
            <Flex row styles={{ gap: 20, flexWrap: 'wrap' }}>
              <ButtonCustom
                text='Guardar'
                type='submit'
                disabled={formState.isSubmitting}
                isLoading={formState.isSubmitting}
              />
            </Flex>
          </Flex>
        </form>
      </CustomCard>
      {serverError && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={serverError ? true : false}
          autoHideDuration={2000}
          onClose={() => setServerError(null)}
        >
          <Alert
            onClose={() => {
              setServerError(null)
            }}
            severity='error'
          >
            {serverError}
          </Alert>
        </Snackbar>
      )}
      {isSubmittedSuccessfully && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={isSubmittedSuccessfully}
          autoHideDuration={2000}
          onClose={() => {
            reset({ newDollarValue: getValues('newDollarValue') })
          }}
        >
          <Alert
            onClose={() => {
              reset({ newDollarValue: getValues('newDollarValue') })
            }}
            severity='success'
          >
            Se guardó exitosamente!
          </Alert>
        </Snackbar>
      )}
    </Flex>
  )
}
