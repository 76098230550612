// Pages
import './fonts/Roundo/Roundo-Regular.otf'
import './fonts/Roundo/Roundo-Bold.otf'
import './fonts/Clash/ClashDisplay-Bold.otf'
import './fonts/Clash/ClashDisplay-Regular.otf'
import './styles.css'
import '@fontsource/oswald' // Defaults to weight 400.

import { App } from 'pages'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Auth0Provider } from '@auth0/auth0-react'
import reportWebVitals from './reportWebVitals'
import { envKeys } from 'config'
import { QueryClient, QueryClientProvider } from 'react-query'
import '@rainbow-me/rainbowkit/styles.css'
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { polygon } from 'wagmi/chains'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

const { chains, provider } = configureChains([polygon], [alchemyProvider(), publicProvider()])

const { connectors } = getDefaultWallets({ appName: 'My RainbowKit App', chains })

const wagmiClient = createClient({ autoConnect: false, connectors, provider })

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2 * 1000,
    },
  },
})

root.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
        <QueryClientProvider client={queryClient}>
          <Auth0Provider
            domain={envKeys.auth0.domain!}
            clientId={envKeys.auth0.clientId!}
            redirectUri={window.location.origin!}
            audience={envKeys.auth0.audience!}
          >
            <App />
          </Auth0Provider>
        </QueryClientProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
