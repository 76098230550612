import { Alert, IconButton, Snackbar, TextField } from '@mui/material'
import { redirect } from 'components/routes/config'
import { CustomCard, CustomText, Flex, Loading, Modal } from 'components/shared'
import ButtonCustom from 'components/shared/Button/Button'
import { envKeys } from 'config'
import { useCostumer } from 'hooks/useCostumer'
import { IconCopy } from 'images'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FontSize, FontWeight } from 'styles'
import { HTTPMethod } from 'utils/apiUtils'
import BaseStyles from './style'

const GenerateApiKey: React.FC = () => {
  const navigate = useNavigate()
  const [success, setSuccess] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const { isLoading: loadingApiKey, data: customerData, refetch } = useCostumer({})
  const apiKeyText = customerData?.apiKey ? 'Actualizar ' : 'Generar'
  const [pin, setPin] = useState('')
  const [modalOpen, setModalOpen] = useState(false)

  const generateApikey = async () => {
    setLoading(true)
    const bearer = `Bearer ${localStorage.getItem('token')}`
    fetch(`${envKeys.api.url}/user/apikey/generate`, {
      method: HTTPMethod.GET,
      headers: new Headers({ 'content-type': 'application/json', Authorization: bearer }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data?.stack?.includes('Error')) {
          throw Error(data.message)
        }
        setSuccess('Apikey generada con éxito')
        refetch()
      })
      .catch(() => {
        setError(true)
      })
      .finally(() => setLoading(false))
  }

  if (loadingApiKey || loading) {
    return (
      <Flex styles={{ marginTop: '60px' }}>
        <Loading />
      </Flex>
    )
  }

  return (
    <Flex styles={{ gap: 20 }}>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Flex styles={{ padding: '0 30px 30px 30px', gap: 20 }}>
          <CustomText fontSize={FontSize.xlarge} fontWeight={FontWeight.extraBold}>
            Nueva Apikey
          </CustomText>
          <CustomText fontSize={FontSize.regular}>Esta seguro que desea continuar?</CustomText>
          <TextField
            onChange={(e) => setPin(e.target.value)}
            helperText={'PIN para obtener nueva apikey'}
            value={pin}
            placeholder='PIN'
          />

          <ButtonCustom
            disabled={envKeys.account.pinApikey !== pin}
            text={`Confirm`}
            onClick={() => {
              generateApikey()
              setModalOpen(false)
              setPin('')
            }}
          />
        </Flex>
      </Modal>
      <CustomCard size='big' style={{ padding: 40 }}>
        <Flex styles={{ gap: 20 }}>
          <CustomText fontSize={FontSize.xxlarge}>Basilic Apikey</CustomText>
          <Flex row styles={BaseStyles.cardContainer}>
            <TextField fullWidth disabled value={customerData?.apiKey ?? 'Genere Apikey para acceder a Basilic API.'} />
            <IconButton
              sx={BaseStyles.iconButton}
              onClick={() => {
                setSuccess('Apikey copiada con éxito')
                navigator.clipboard.writeText(customerData?.apiKey ?? '')
              }}
            >
              <IconCopy />
            </IconButton>
          </Flex>
          <CustomText fontSize={FontSize.small} style={{ alignSelf: 'flex-start' }}>
            *Apikey para acceder a Basilic API.
            <a href='https://hackmd.io/@RqDLCDAKTD6W0wlDQsX0gQ/Bklms9wqR' target='_blank' rel='noreferrer'>
              Más informacion
            </a>
          </CustomText>
          <Flex row styles={{ gap: 20, flexWrap: 'wrap' }}>
            <ButtonCustom text={`${apiKeyText} Apikey`} onClick={() => setModalOpen(true)} />
            <ButtonCustom text='Atras' onClick={() => navigate(redirect.home())} />
          </Flex>
        </Flex>
      </CustomCard>
      {success && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={!!success}
          autoHideDuration={2000}
          onClose={() => setSuccess('')}
        >
          <Alert onClose={() => setSuccess('')} severity='success'>
            {success}
          </Alert>
        </Snackbar>
      )}

      {error && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={error}
          autoHideDuration={2000}
          onClose={() => setError(false)}
        >
          <Alert onClose={() => setError(false)} severity='error'>
            Algo salio mal, intente nuevamente.
          </Alert>
        </Snackbar>
      )}
    </Flex>
  )
}

export default GenerateApiKey
