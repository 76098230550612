import { Colors } from 'styles'

const BaseStyles = {
  iconButton: {
    width: 36,
    height: 36,
    backgroundColor: Colors.violetLight03,
    borderRadius: '8px',
    '&:hover': {
      background: Colors.violetDark01,
    },
  },
  cardContainer: {
    gap: 20,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export default BaseStyles
