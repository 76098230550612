import React, { useState } from 'react'
// Material UI
import { CustomCard, CustomText, Flex, Loading, Modal } from 'components/shared'
// Images
import { FontSize, FontWeight } from 'styles'
import ButtonCustom from 'components/shared/Button/Button'
import { Alert, Divider, IconButton, Snackbar, TextField } from '@mui/material'
import { emailRegex } from 'utils/objectUtils'
import { envKeys } from 'config'
import { redirect } from 'components/routes/config'
import { useNavigate } from 'react-router-dom'
import { useAllWhitelist } from 'hooks/useAllWhitelist'
import { DeleteIcon } from 'images'

interface FormProps {}

const Admin: React.FC<FormProps> = () => {
  const navigate = useNavigate()
  const { isLoading: loadingWhitelist, data: whitelist, refetch } = useAllWhitelist({})
  const [email, setEmail] = useState('')
  const [fee, setFee] = useState(2)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalOpenDelete, setModalOpenDelete] = useState(false)
  const [deleteUser, setDeleteUser] = useState('')
  const validEmail = email.match(emailRegex) === null

  const addEmail = () => {
    setLoading(true)
    const bearer = `Bearer ${localStorage.getItem('token')}`
    fetch(`${envKeys.api.url}/admin/addUser`, {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/json', Authorization: bearer }),
      body: JSON.stringify({ userEmail: email, fee }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.stack?.includes('Error')) {
          throw Error(data.message)
        }
        setSuccess(true)
        refetch()
      })
      .catch((err) => {
        console.error(err)
        setError(true)
      })
      .finally(() => setLoading(false))
  }

  const deleteUserCall = (deleteEmail: string) => {
    setLoading(true)
    const bearer = `Bearer ${localStorage.getItem('token')}`
    fetch(`${envKeys.api.url}/admin/addUser`, {
      method: 'DELETE',
      headers: new Headers({ 'content-type': 'application/json', Authorization: bearer }),
      body: JSON.stringify({ userEmail: deleteEmail, fee }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.stack?.includes('Error')) {
          throw Error(data.message)
        }
        setSuccess(true)
        setModalOpen(false)
        setModalOpenDelete(false)
        refetch()
      })
      .catch((err) => {
        console.error(err)
        setError(true)
      })
      .finally(() => setLoading(false))
  }

  if (loading || loadingWhitelist) {
    return (
      <Flex styles={{ marginTop: '60px' }}>
        <Loading />
      </Flex>
    )
  }
  const feeError = fee <= 0 || fee >= 100

  return (
    <Flex>
      <CustomCard size='medium'>
        <Flex styles={{ padding: 40, gap: 40 }}>
          <CustomText fontSize={FontSize.xxlarge} fontWeight={FontWeight.bold}>
            Agregar Usuarios
          </CustomText>
          <TextField
            type='email'
            fullWidth
            label='Email'
            onChange={(e) => setEmail(e.target.value)}
            error={validEmail}
            helperText={validEmail ? 'Incorrect email format' : ''}
          />
          <TextField
            fullWidth
            label='Fee percentage'
            type='number'
            value={fee}
            error={feeError}
            helperText={feeError && 'Fee tiene que ser positivo mayor a 0 y menor a 100'}
            onChange={(e) => setFee(parseFloat(e.target.value))}
          />
          <Flex row styles={{ gap: 20 }}>
            <ButtonCustom text='Ver usuarios' onClick={() => setModalOpen(true)} />
            <ButtonCustom disabled={validEmail || fee <= 0} text='Agregar Usuario' onClick={() => addEmail()} />
          </Flex>
          <ButtonCustom text='Atrás' onClick={() => navigate(redirect.admin())} />
        </Flex>
      </CustomCard>
      {success && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={success}
          autoHideDuration={4000}
          onClose={() => setSuccess(false)}
        >
          <Alert
            onClose={() => {
              setSuccess(false)
            }}
            severity='success'
          >
            Operacion Exitosa!
          </Alert>
        </Snackbar>
      )}
      {error && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={error}
          autoHideDuration={4000}
          onClose={() => setError(false)}
        >
          <Alert
            onClose={() => {
              setError(false)
            }}
            severity='error'
          >
            Algo salio mal, intente nuevamente.
          </Alert>
        </Snackbar>
      )}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Flex styles={{ padding: '0 30px 30px 30px', gap: 20, minWidth: 470 }}>
          <CustomText fontSize={FontSize.xlarge} fontWeight={FontWeight.extraBold}>
            Usuarios con permisos
          </CustomText>
          {whitelist.map((user: any) => (
            <>
              <Flex row styles={{ gap: 40, justifyContent: 'space-between', width: '90%' }}>
                <CustomText fontSize={FontSize.regular} style={{ width: 180 }}>
                  {user.email}
                </CustomText>
                <CustomText fontSize={FontSize.regular}>Fee: {user.fee}%</CustomText>
                <IconButton
                  color='primary'
                  sx={{ width: 38, height: 38, border: '1px solid red', borderRadius: '12px' }}
                  onClick={() => {
                    setModalOpenDelete(true)
                    setDeleteUser(user.email)
                  }}
                >
                  <DeleteIcon style={{ fill: 'red' }} />
                </IconButton>
              </Flex>
              <Divider sx={{ width: '100%' }} />
            </>
          ))}
          <ButtonCustom text='Cerrar Modal' onClick={() => setModalOpen(false)} />
        </Flex>
      </Modal>
      <Modal open={modalOpenDelete} onClose={() => setModalOpenDelete(false)}>
        <Flex styles={{ padding: '0 30px 30px 30px', gap: 20, minWidth: 470 }}>
          <CustomText fontWeight={FontWeight.extraBold} fontSize={FontSize.xlarge}>
            Eliminar Usuario
          </CustomText>
          <CustomText fontSize={FontSize.regular}>
            Esta seguro que desea eliminar el usuario <b>{deleteUser}</b>?
          </CustomText>
          <Flex row styles={{ gap: 20 }}>
            <ButtonCustom text='Cancelar' onClick={() => setModalOpenDelete(false)} />
            <ButtonCustom text='Eliminar' onClick={() => deleteUserCall(deleteUser)} />
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  )
}

export default Admin
