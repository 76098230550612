import { useAuth0 } from '@auth0/auth0-react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Snackbar } from '@mui/material'
import UploadFile from 'components/business/UploadFile/UploadFile'
import { redirect } from 'components/routes/config'
import { CustomCard, CustomText, Flex, Loading, TextField } from 'components/shared'
import ButtonCustom from 'components/shared/Button/Button'
import { envKeys } from 'config'
import useDevice from 'hooks/useDevice'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { FontSize } from 'styles'
import { isObjectEmpty } from 'utils/objectUtils'
import { FieldsFormType, defaultValues, schema } from './validation'

const ProductForm: React.FC = () => {
  const navigate = useNavigate()
  const [mintNft] = useState<boolean>(true)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(null)
  const [files, setFiles] = useState<any>(null)

  const [excelFile, setExcelFile] = useState<any>(null)
  const { isMobile } = useDevice()
  const { user } = useAuth0()
  const {
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm<FieldsFormType>({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(schema),
  })
  const productPrice = watch('productPrice')
  const productName = watch('productName')
  const invalidForm = !isObjectEmpty(errors) || productName.length < 1

  function sendProductData(productData: any) {
    const bearer = `Bearer ${localStorage.getItem('token')}`
    fetch(`${envKeys.api.url}/uploadProduct`, {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/json', Authorization: bearer }),
      body: JSON.stringify(productData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.stack?.includes('Error')) {
          throw Error(data.message)
        }
        setSuccess(true)
        reset()
        setFiles(null)
      })
      .catch((err) => {
        if (err?.message?.includes('price')) {
          setError('No se pueden agregar productos con el mismo precio')
        } else {
          setError('Algo salio mal, intente nuevamente.')
        }
      })
  }

  function sendExcelData(productData: any) {
    setLoading(true)
    const bearer = `Bearer ${localStorage.getItem('token')}`
    fetch(`${envKeys.api.url}/uploadListProducts`, {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/json', Authorization: bearer }),
      body: JSON.stringify(productData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.stack?.includes('Error')) {
          throw Error(data.message)
        }
        setSuccess(true)
        reset()
        setFiles(null)
      })
      .catch((err) => {
        if (err?.message?.includes('price')) {
          setError('No se pueden agregar productos con el mismo precio')
        } else {
          setError('Algo salio mal, intente nuevamente.')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const postCustomer = async () => {
    if (files) {
      const reader = new FileReader()
      reader.onload = () => {
        const fileData = reader.result
        const jsonData = {
          file: fileData,
          email: user?.email!,
          productPrice,
          productName,
          mintNft,
        }
        sendProductData(jsonData)
      }
      reader.readAsDataURL(files)
    } else {
      sendProductData({
        email: user?.email!,
        productPrice,
        productName,
        mintNft,
      })
    }
  }

  const uploadExcelFile = async () => {
    const reader = new FileReader()
    reader.onload = () => {
      const fileData = reader.result
      const jsonData = {
        file: fileData,
        email: user?.email!,
        productPrice,
        productName,
        mintNft,
      }
      sendExcelData(jsonData)
    }
    reader.readAsDataURL(excelFile)
  }

  if (loading) {
    return (
      <Flex styles={{ marginTop: '60px' }}>
        <Loading />
      </Flex>
    )
  }

  return (
    <Flex styles={{ gap: 20, padding: isMobile ? '0' : '0 30%' }}>
      <ButtonCustom text='Atrás' onClick={() => navigate(redirect.home())} />
      <CustomCard size='big' style={{ padding: 40 }}>
        <Flex styles={{ gap: 20 }}>
          <CustomText fontSize={FontSize.xxlarge}>Cargar Producto</CustomText>
          <TextField
            control={control}
            name='productName'
            textFieldProps={{ placeholder: 'Ingrese Nombre del producto', fullWidth: true }}
          />
          <UploadFile files={files} setFiles={setFiles} type='imagen' />
          <TextField
            control={control}
            name='productPrice'
            textFieldProps={{
              type: 'number',
              fullWidth: true,
              error: !!errors.productPrice?.message,
              helperText: 'Precio Producto en USDT',
            }}
          />
          <Flex row styles={{ gap: 20, flexWrap: 'wrap' }}>
            <ButtonCustom text='Agregar Producto' onClick={() => postCustomer()} disabled={invalidForm} />
          </Flex>
        </Flex>
      </CustomCard>
      <CustomCard size='big' style={{ padding: 40 }}>
        <Flex styles={{ gap: 20 }}>
          <CustomText fontSize={FontSize.xxlarge}>Cargar Lista de Productos</CustomText>

          <UploadFile files={excelFile} setFiles={setExcelFile} type='excel' />
          <Flex row styles={{ gap: 20, flexWrap: 'wrap' }}>
            <ButtonCustom text='Subir archivo' onClick={() => uploadExcelFile()} disabled={!excelFile} />
          </Flex>
        </Flex>
      </CustomCard>
      {success && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={success}
          autoHideDuration={2000}
          onClose={() => setSuccess(false)}
        >
          <Alert
            onClose={() => {
              setSuccess(false)
            }}
            severity='success'
          >
            Producto/s Agregado !
          </Alert>
        </Snackbar>
      )}
      {error?.length > 0 && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={error?.length > 0}
          autoHideDuration={2000}
          onClose={() => setError(false)}
        >
          <Alert
            onClose={() => {
              setError(false)
            }}
            severity='error'
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </Flex>
  )
}

export default ProductForm
