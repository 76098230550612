// Material
import { Button, ButtonProps, CircularProgress } from '@mui/material'
import { ConnectButton } from '@rainbow-me/rainbowkit'
// Shared Components
import CustomText from 'components/shared/CustomText'
// Hooks
import useStyles from 'hooks/useStyles'
import React from 'react'
// Styles
import { FontFamily, FontWeight } from 'styles/font'

//Types
import { CustomTextProps } from '../CustomText/CustomText'
import getStyles from './style'

export interface CustomButtonProps extends ButtonProps {
  text?: string
  textProps?: CustomTextProps
  connect?: boolean
  isLoading?: boolean
}

const ButtonCustom: React.FC<CustomButtonProps> = ({
  text = 'Delegate',
  color,
  size = 'medium',
  variant = 'contained',
  disabled = false,
  textProps,
  connect = false,
  style,
  ...buttonProps
}) => {
  const { BaseStyles } = useStyles({
    getStyles: (isMobile) => getStyles({ isMobile, color, size, disabled }),
  })
  if (connect) {
    return (
      <div className={color === 'secondary' ? 'connect-button secondary' : 'connect-button'}>
        <ConnectButton accountStatus={'avatar'} />
      </div>
    )
  }

  return (
    <Button
      variant={variant}
      disabled={disabled || buttonProps.isLoading}
      disableElevation
      style={{ ...BaseStyles.button, ...style }}
      {...buttonProps}
      onClick={(e) => {
        if (buttonProps && buttonProps.onClick) buttonProps.onClick(e)
      }}
    >
      {buttonProps.isLoading && <CircularProgress size={20} color='secondary' />}
      <CustomText fontWeight={FontWeight.medium} fontFamily={FontFamily.primary} {...textProps}>
        {text}
      </CustomText>
    </Button>
  )
}

export default ButtonCustom
