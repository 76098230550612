export type FormatCurrencyDisplay = 'code' | 'symbol' | 'narrowSymbol' | 'name'

export type CurrencyFormatOptionsType = {
  style: 'currency'
  currency: 'USD' | 'ARS'
  currencyDisplay: FormatCurrencyDisplay
}

export type DecimalFormatOptionsType = {
  style: 'decimal'
}

export type NumberFormatOptionsType = CurrencyFormatOptionsType | DecimalFormatOptionsType

const usdFormatOptions: NumberFormatOptionsType = {
  currency: 'USD',
  style: 'currency',
  currencyDisplay: 'symbol',
}

export const usdFormatter = new Intl.NumberFormat('en-US', usdFormatOptions)

const arsFormatOptions: NumberFormatOptionsType = {
  currency: 'ARS',
  style: 'currency',
  currencyDisplay: 'symbol',
}

export const arsFormatter = new Intl.NumberFormat('en-US', arsFormatOptions)
