// Internal components
import ListAllProducts from './ListProducts/ListAllProducts'
import ListProductsActive from './ListProductsActive/ListProductsActive'
import ListProductsStatus from './ListProductsStatus/ListProductsStatus'
import SellProduct from './SellProduct/SellProduct'

const paths = {
  empty: '/',
  list: '/:productId',
  status: '/status',
  active: '/active',
}

export const routes = [
  {
    path: paths.empty,
    Page: ListAllProducts,
    exact: true,
  },
  {
    path: paths.list,
    Page: SellProduct,
    exact: true,
  },
  {
    path: paths.status,
    Page: ListProductsStatus,
    exact: true,
  },
  {
    path: paths.active,
    Page: ListProductsActive,
    exact: true,
  },
]
