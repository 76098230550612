// Shared Components
import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@mui/system'
import { CustomCard, CustomText, Flex } from 'components/shared'
// Types
import Button, { CustomButtonProps } from 'components/shared/Button'
// Hooks
import React from 'react'
// Styles
import { Colors, FontFamily, FontSize } from 'styles'

// Utils

interface CardWithImageProps {
  Icon?: Icon
  imageUrl?: string
  title?: string
  description?: string[]
  callToActionProps?: CustomButtonProps
  CallToAction?: React.FC<CustomButtonProps>
  variant?: 'vertical' | 'horizontal'
  FooterComponent?: React.FC
}

const EmptyStateCard: React.FC<CardWithImageProps> = () => {
  const { loginWithRedirect } = useAuth0()

  return (
    <CustomCard
      size='big'
      style={{
        zIndex: 2,
        gap: 12,
        display: 'flex',
        flexDirection: 'column',
        minWidth: '300px',
        margin: 'auto',
        position: 'relative',
        textAlign: 'center',
      }}
    >
      <Box sx={{ background: '#E0D9F3', display: 'flex', justifyContent: 'center' }}></Box>
      <Box p={5} style={{ gap: 20 }}>
        <CustomText
          fontSize={FontSize.xlarge}
          fontFamily={FontFamily.primary}
          fontWeight={700}
          style={{ color: Colors.blackLight, paddingBottom: 20 }}
        >
          WELCOME TO BASILIC
        </CustomText>
        <CustomText fontWeight={400} fontSize={FontSize.regular} style={{ color: Colors.black }}>
          This platform enables business to buy and sell products using cryptocurrencies.
        </CustomText>
        <br />
        <br />
        <CustomText fontWeight={400} fontSize={FontSize.regular} style={{ color: Colors.black }}>
          Log in with your Google account here:
        </CustomText>
        <br />
        <Flex row>
          <Button
            onClick={() => loginWithRedirect()}
            variant='outlined'
            text='Sign Up'
            style={{ color: Colors.blackLight, border: `1px solid ${Colors.blackLight}` }}
          />
          <Button
            onClick={() => loginWithRedirect()}
            variant='outlined'
            text='Login'
            style={{ marginLeft: 12, color: Colors.blackLight, border: `1px solid ${Colors.blackLight}` }}
          />
        </Flex>
      </Box>
    </CustomCard>
  )
}

export default EmptyStateCard
