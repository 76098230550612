import { useAuth0 } from '@auth0/auth0-react'
import { envKeys } from 'config'
import { useQuery } from 'react-query'
import { HTTPMethod, HttpRequest } from 'utils/apiUtils'

const fetchProducts = async () => {
  const bearer = `Bearer ${localStorage.getItem('token')}`
  const request: HttpRequest = {
    method: HTTPMethod.GET,
    headers: new Headers({ 'content-type': 'application/json', Authorization: bearer }),
  }
  const response = await fetch(`${envKeys.api.url}/admin/walletFee`, request).then((res) => res.json())

  return response
}

const useAdminWallet = ({ enabled = true, options }: { enabled?: boolean; options?: any }) => {
  const { user } = useAuth0()

  return useQuery(['adminWallet'], () => fetchProducts(), {
    enabled: enabled && !!user?.email && !!localStorage.getItem('token'),
    ...options,
  })
}

export { useAdminWallet }
