const getStyles: StylesFn = () => ({
  closeContainer: {
    alignItems: 'flex-end',
  },
  closeIcon: {
    maxWidth: 40,
  },
})

export default getStyles
