import React from 'react'
// Material UI
import { CustomCard, CustomText, Flex } from 'components/shared'
// Images
import ButtonCustom from 'components/shared/Button/Button'
import { FontSize, FontWeight } from 'styles'

interface FormProps {}

const ErrorAccess: React.FC<FormProps> = () => {
  return (
    <Flex>
      <CustomCard size='medium'>
        <Flex styles={{ padding: 40, gap: 40 }}>
          <CustomText fontSize={FontSize.xxlarge} fontWeight={FontWeight.bold}>
            No Access
          </CustomText>
          <ButtonCustom
            text='Solicitar Acceso a la APP'
            onClick={() =>
              window.open(
                'https://docs.google.com/forms/d/1XYS-P8Bi6EZPaK2DOk2afkoZLGSrD36eVzq1uBPbvks/viewform?edit_requested=true',
                '_blank'
              )
            }
          />
        </Flex>
      </CustomCard>
    </Flex>
  )
}

export default ErrorAccess
