// Material
import { CircularProgress } from '@mui/material'
// Shared
import { Flex } from 'components/shared'
import React from 'react'

interface LoadingProps {
  color?: 'primary' | 'secondary'
}

const Loading: React.FC<LoadingProps> = ({ color }) => {
  return (
    <Flex styles={{ color, alignSelf: 'center', justifySelf: 'center' }}>
      <CircularProgress color={color} />
    </Flex>
  )
}

export default Loading
