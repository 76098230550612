import * as yup from 'yup'

export const schema = yup
  .object({
    productName: yup.string().min(2).required(),
    productPrice: yup.number().positive().required(),
  })
  .required()

export type FieldsFormType = yup.InferType<typeof schema>

export const defaultValues = {
  productName: '',
  productPrice: 10,
}
