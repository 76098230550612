// Components
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
} from '@mui/material'
import ProductCard from 'components/business/ProductCard/ProductCard'
import { CustomCard, CustomText, Flex, Loading, Modal } from 'components/shared'
import ButtonCustom from 'components/shared/Button/Button'
import { envKeys } from 'config'
import { useCostumer } from 'hooks/useCostumer'
import useDevice from 'hooks/useDevice'
import { useProductList } from 'hooks/useProductList'
import { useProductListStatus } from 'hooks/useProductListStatus'
import useStepper from 'hooks/useStepper'
import { IconCopy, SuccessLottie } from 'images'
import Lottie from 'lottie-react'
import { useEffect, useState } from 'react'
import ConfettiExplosion from 'react-confetti-explosion'
import QRCode from 'react-qr-code'
import { useNavigate, useParams } from 'react-router-dom'
import { Colors, FontSize, FontWeight } from 'styles'
import BaseStyles from './style'

export enum ProductStatus {
  listed = 'listed',
  onSale = 'onSale',
  sold = 'sold',
  cancelled = 'cancelled',
}

const SellProduct: React.FC = () => {
  const [error, setError] = useState<any>(false)
  const [, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successCopy, setSuccessCopy] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [successSell, setSuccessSell] = useState(false)
  const [claimNft, setClaimNft] = useState(true)
  const [selectedProduct, setSelectedProduct] = useState<any>()
  const { isLoading: loadingProducts, data: productsList } = useProductList({})
  const { isLoading: loadingCustomer, data: customer } = useCostumer({})
  const { isMobile } = useDevice()
  const { currentStep, goToNextStep } = useStepper(1)
  const navigate = useNavigate()
  const { productId } = useParams()
  const { isLoading: loadingListing, data: listing, refetch: refetchListing } = useProductListStatus({})
  const [mintNft, setMintNft] = useState<boolean>(true)

  useEffect(() => {
    if (!loadingProducts) {
      const product = productsList.find((p: Product) => p.id === productId)
      setSelectedProduct(product)
    }
  }, [loadingProducts, productId, productsList])

  useEffect(() => {
    if (selectedProduct && selectedProduct.status === ProductStatus.onSale) {
      goToNextStep()
    }
  }, [loadingListing, selectedProduct])

  useEffect(() => {
    if (listing && selectedProduct) {
      let newState = listing.filter((p: any) => p.productId === productId)
      newState = newState.sort((a: any, b: any) => (new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1))
      console.log(newState[0], newState[0]?.status, selectedProduct.status !== newState[0]?.status)
      if (newState[0] && newState[0]?.status && selectedProduct.status !== newState[0]?.status) {
        const newPr = { ...selectedProduct, status: newState[0]?.status }
        setSelectedProduct(newPr)
        if (currentStep === 1 && newState[0]?.status === ProductStatus.sold) setSuccessSell(true)
      }
    }
  }, [listing, loadingListing, selectedProduct])

  const sendProductData = () => {
    setLoading(true)
    const bearer = `Bearer ${localStorage.getItem('token')}`
    fetch(`${envKeys.api.url}/createListing`, {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/json', Authorization: bearer }),
      body: JSON.stringify({ id: productId, price: selectedProduct?.price, mintNft, claimNft }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.stack?.includes('Error')) {
          throw data
        }
        setSuccess(true)
        refetchListing()
        goToNextStep()
      })
      .catch((err) => {
        console.log({ err })
        if (err?.message?.includes('Cant list')) {
          setError('No puede vender dos productos con mismo precio al mismo tiempo')
        } else setError('Algo salio mal, intente nuevamente.')
      })
      .finally(() => setLoading(false))
  }

  if (loadingProducts || loadingCustomer) {
    return (
      <Flex styles={{ marginTop: '60px' }}>
        <Loading />
      </Flex>
    )
  }

  return (
    <Flex styles={{ gap: 20 }}>
      {successSell && selectedProduct.status === ProductStatus.sold && <ConfettiExplosion />}
      <CustomCard size='medium' style={{ padding: isMobile ? 10 : 40, background: Colors.extraLightgray }}>
        <Flex styles={{ gap: 20 }}>
          <CustomText fontSize={FontSize.xxlarge}>Vender Producto</CustomText>
          <ProductCard
            title={selectedProduct?.name}
            subtitle={'Price: ' + selectedProduct?.price + ' USDT'}
            image={selectedProduct?.uploadedFileUrl}
            variant='horizontal'
            disableHover
          />
          {currentStep === 0 && (
            <>
              {selectedProduct && customer && (
                <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                  <Flex styles={{ padding: '0 30px 30px 30px', gap: 20 }}>
                    <CustomText fontWeight={FontWeight.extraBold} fontSize={FontSize.xlarge}>
                      Vender Producto
                    </CustomText>
                    <CustomText fontSize={FontSize.regular}>Esta seguro que desea iniciar la venta?</CustomText>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={true}
                          color='primary'
                          onChange={() => setMintNft(!mintNft)}
                          checked={mintNft}
                        />
                      }
                      label='Mint Nft con la compra exitosa'
                    />
                    {mintNft && (
                      <FormControl>
                        <FormLabel sx={{ marginBottom: 2 }} id='demo-radio-buttons-group-label'>
                          Como desea recibir el NFT del producto?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby='demo-radio-buttons-group-label'
                          defaultValue='claim'
                          name='radio-buttons-group'
                          onChange={(e) => {
                            setClaimNft(e.target.value === 'claim')
                          }}
                        >
                          <FormControlLabel value='claim' control={<Radio />} label='Generar link para mintear NFT ' />
                          <FormControlLabel
                            value='mint'
                            control={<Radio />}
                            label='Mintear a la address que envía el pago'
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                    <ButtonCustom text='Crear Venta' onClick={() => sendProductData()} />
                  </Flex>
                </Modal>
              )}
              <ButtonCustom text='Crear Venta' onClick={() => setModalOpen(true)} />
            </>
          )}
          {currentStep === 1 && (
            <>
              {successSell ? (
                <>
                  <CustomText fontSize={FontSize.xlarge}>Pago recibido!</CustomText>
                  <Lottie loop animationData={SuccessLottie} />
                </>
              ) : (
                <>
                  <QRCode value={customer?.split ?? ''} />
                  <Flex row styles={{ gap: 10, flexWrap: 'flex-wrap' }}>
                    <CustomText fontSize={isMobile ? FontSize.small : FontSize.default}>{customer?.split}</CustomText>
                    <IconButton
                      sx={BaseStyles.iconButton}
                      onClick={() => {
                        navigator.clipboard.writeText(customer?.split ?? '')
                        setSuccessCopy(true)
                      }}
                    >
                      <IconCopy />
                    </IconButton>
                  </Flex>
                </>
              )}
            </>
          )}
          <ButtonCustom text='Atrás' onClick={() => navigate(-1)} />
        </Flex>
      </CustomCard>
      {success && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={success}
          autoHideDuration={2000}
          onClose={() => setSuccess(false)}
        >
          <Alert
            onClose={() => {
              setSuccess(false)
            }}
            severity='success'
          >
            Venta creada!
          </Alert>
        </Snackbar>
      )}
      {successCopy && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={successCopy}
          autoHideDuration={6000}
          onClose={() => setSuccessCopy(false)}
        >
          <Alert
            onClose={() => {
              setSuccessCopy(false)
            }}
            severity='success'
          >
            Wallet Copiada!
          </Alert>
        </Snackbar>
      )}
      {error && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={error}
          autoHideDuration={2000}
          onClose={() => setError(false)}
        >
          <Alert
            onClose={() => {
              setError(false)
            }}
            severity='error'
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </Flex>
  )
}

export default SellProduct
