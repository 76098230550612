import { Colors } from 'styles'

const BaseStyles: Styles = {
  iconButton: {
    width: 36,
    height: 36,
    backgroundColor: Colors.violetLight03,
    borderRadius: '8px',
    '&:hover': {
      background: Colors.violetDark01,
    },
  },
}

export default BaseStyles
