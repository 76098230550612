// Components
import { Alert, Snackbar } from '@mui/material'
import ProductCard from 'components/business/ProductCard/ProductCard'
import { redirect } from 'components/routes/config'
import { CustomCard, CustomText, Flex, Loading } from 'components/shared'
import ButtonCustom from 'components/shared/Button/Button'
import useDevice from 'hooks/useDevice'
import { useProductList } from 'hooks/useProductList'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Colors, FontSize } from 'styles'
import { ProductStatus } from '../SellProduct/SellProduct'
import { useProductListStatus } from 'hooks/useProductListStatus'

const ListProductsActive: React.FC = () => {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [pendingProducts, setPendingProducts] = useState([])
  const { isLoading: loadingProducts, data: productsList } = useProductList({})
  const { isLoading: loadingListing, data: listing } = useProductListStatus({})

  const { isMobile } = useDevice()
  const navigate = useNavigate()

  useEffect(() => {
    if (listing?.length > 0 && productsList?.length > 0) {
      const pendingP = listing.filter((p: any) => p.status === ProductStatus.onSale)
      const pendingProductsArr = pendingP.map((p: any) => {
        const extraInfoP = productsList.find((p2: Product) => p2.id === p.productId)

        return { ...extraInfoP, ...p }
      })
      setPendingProducts(pendingProductsArr)
    }
  }, [listing, productsList])

  if (loadingProducts || loadingListing) {
    return (
      <Flex styles={{ marginTop: '60px' }}>
        <Loading />
      </Flex>
    )
  }

  return (
    <Flex styles={{ gap: 20 }}>
      <ButtonCustom text='Atras' onClick={() => navigate(redirect.home())} />
      <CustomCard size='medium' style={{ padding: isMobile ? 10 : 40, background: Colors.extraLightgray }}>
        <Flex styles={{ gap: 20 }}>
          {pendingProducts?.length > 0 && (
            <>
              <CustomText fontSize={FontSize.xxlarge}>Productos en Venta Activos</CustomText>
              {pendingProducts.map((p: Product) => (
                <ProductCard
                  title={p.name}
                  subtitle={'Price: ' + p.price + ' USDT'}
                  subtitle2={'Status: ' + p.status}
                  image={p.uploadedFileUrl}
                  variant='horizontal'
                  cardOnclick={() => navigate(redirect.sellProductId({ productId: p.productId! }))}
                />
              ))}
            </>
          )}
        </Flex>
      </CustomCard>
      {success && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={success}
          autoHideDuration={2000}
          onClose={() => setSuccess(false)}
        >
          <Alert
            onClose={() => {
              setSuccess(false)
            }}
            severity='success'
          >
            Wallet Updated!
          </Alert>
        </Snackbar>
      )}
      {error && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={error}
          autoHideDuration={2000}
          onClose={() => setError(false)}
        >
          <Alert
            onClose={() => {
              setError(false)
            }}
            severity='error'
          >
            Algo salio mal, intente nuevamente.
          </Alert>
        </Snackbar>
      )}
    </Flex>
  )
}

export default ListProductsActive
