import { useAuth0 } from '@auth0/auth0-react'
import { Alert, Snackbar, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { redirect } from 'components/routes/config'
import { CustomCard, CustomText, Flex, Loading, Modal } from 'components/shared'
import ButtonCustom from 'components/shared/Button/Button'
import { envKeys } from 'config'
import { useCostumer } from 'hooks/useCostumer'
import useDevice from 'hooks/useDevice'
import useStepper from 'hooks/useStepper'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FontSize, FontWeight } from 'styles'
import { HTTPMethod } from 'utils/apiUtils'
import { ethAddressRegex } from 'utils/objectUtils'
import { useAccount, useDisconnect } from 'wagmi'

interface FormProps {}

const UploadWallet: React.FC<FormProps> = () => {
  const navigate = useNavigate()
  const [wallet, setWallet] = useState('')
  const [pin, setPin] = useState('')
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [, setPinCorrect] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [errorWallet, setErrorWallet] = useState(false)
  const validWallet = wallet.match(ethAddressRegex) === null
  const { isLoading: loadingApiKey, data: customerData } = useCostumer({})
  const { data: rainbowData } = useAccount()
  const { user } = useAuth0()
  const { isMobile } = useDevice()
  const walletText = customerData?.wallet ? 'Actualizar ' : 'Agregar'
  const { disconnect, isLoading } = useDisconnect()
  const { currentStep, goToNextStep } = useStepper(1)

  useEffect(() => {
    if (rainbowData?.address && !isLoading) {
      setWallet(rainbowData.address)
      setModalOpen(true)
    } else {
      setWallet('')
    }
  }, [isLoading, rainbowData])

  useEffect(() => {
    return () => disconnect()
  }, [])

  const postCustomer = async () => {
    setLoading(true)
    const bearer = `Bearer ${localStorage.getItem('token')}`
    fetch(`${envKeys.api.url}/customer`, {
      method: HTTPMethod.POST,
      headers: new Headers({ 'content-type': 'application/json', Authorization: bearer }),
      body: JSON.stringify({
        name: user?.name,
        email: user?.email,
        wallet,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data?.stack?.includes('Error')) {
          throw Error(data.message)
        }
        setSuccess(true)
        setTimeout(() => window.history.back(), 3000)
      })
      .catch((err) => {
        if (err?.message?.includes('duplicate key')) setErrorWallet(true)
        else setError(true)
      })
      .finally(() => setLoading(false))
  }

  if (loadingApiKey) {
    return (
      <Flex styles={{ marginTop: '60px' }}>
        <Loading />
      </Flex>
    )
  }

  return (
    <Flex styles={{ gap: 20 }}>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Flex styles={{ padding: '0 30px 30px 30px', gap: 20 }}>
          <CustomText fontSize={FontSize.xlarge} fontWeight={FontWeight.extraBold}>
            {walletText} Wallet
          </CustomText>
          <CustomText fontSize={FontSize.regular}>Compruebe que la wallet es correcta, desea continuar?</CustomText>
          <CustomText fontSize={FontSize.regular} fontWeight={FontWeight.bold}>
            {wallet}
          </CustomText>

          {loading ? <Loading /> : <ButtonCustom text={`${walletText} Wallet`} onClick={() => postCustomer()} />}
        </Flex>
      </Modal>
      <CustomCard size='big' style={{ padding: 40 }}>
        {currentStep === 0 && (
          <Flex styles={{ gap: 20 }}>
            <TextField
              onChange={(e) => setPin(e.target.value)}
              helperText={'Pin de ADMIN para cambio de wallet'}
              sx={{ width: isMobile ? '100%' : rainbowData?.address ? '75%' : '100%' }}
              value={pin}
              placeholder='Pin de ADMIN para cambio de wallet'
            />
            <Flex row styles={{ gap: 20, flexWrap: 'wrap' }}>
              <ButtonCustom text='Atras' onClick={() => navigate(redirect.home())} />
              <ButtonCustom
                text={'Continuar'}
                disabled={envKeys.account.pin !== pin}
                onClick={() => {
                  setPinCorrect(true)
                  goToNextStep()
                }}
              />
            </Flex>
          </Flex>
        )}
        {currentStep === 1 && (
          <Flex styles={{ gap: 20 }}>
            {customerData?.wallet && (
              <TextField fullWidth disabled value={customerData?.wallet} helperText='Wallet guardada' />
            )}
            <Flex
              row
              styles={{
                gap: 20,
                flexWrap: isMobile ? 'wrap' : '',
                width: '100%',
                alignItems: rainbowData?.address ? 'center' : 'flex-start',
              }}
            >
              <TextField
                fullWidth
                onChange={(e) => setWallet(e.target.value)}
                error={validWallet}
                helperText={validWallet ? 'Direccion POLYGON Incorrecta' : ''}
                sx={{ width: isMobile ? '100%' : rainbowData?.address ? '75%' : '100%' }}
                value={wallet}
              />
              <Box sx={{ minWidth: rainbowData?.address ? '' : '20%' }}>
                <ConnectButton showBalance={false} />
              </Box>
            </Flex>
            <CustomText fontSize={FontSize.small} style={{ alignSelf: 'flex-start', marginLeft: 4, marginBottom: 24 }}>
              *Asegurese que la wallet corresponde a la red de POLYGON, en caso contrario los fondos podrian perderse
            </CustomText>
            <Flex row styles={{ gap: 20, flexWrap: 'wrap' }}>
              <ButtonCustom text={`${walletText} Wallet`} onClick={() => setModalOpen(true)} disabled={validWallet} />
              <ButtonCustom text='Atras' onClick={() => navigate(redirect.home())} />
            </Flex>
          </Flex>
        )}
      </CustomCard>
      {success && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={success}
          autoHideDuration={2000}
          onClose={() => setSuccess(false)}
        >
          <Alert
            onClose={() => {
              setSuccess(false)
            }}
            severity='success'
          >
            Wallet Updated!
          </Alert>
        </Snackbar>
      )}
      {error && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={error}
          autoHideDuration={2000}
          onClose={() => setError(false)}
        >
          <Alert
            onClose={() => {
              setError(false)
            }}
            severity='error'
          >
            Algo salio mal, intente nuevamente.
          </Alert>
        </Snackbar>
      )}
      {errorWallet && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={errorWallet}
          autoHideDuration={6000}
          onClose={() => setErrorWallet(false)}
        >
          <Alert
            onClose={() => {
              setErrorWallet(false)
            }}
            severity='error'
          >
            La wallet ya existe, ingrese otra por favor{' '}
          </Alert>
        </Snackbar>
      )}
    </Flex>
  )
}

export default UploadWallet
