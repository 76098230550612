import { useAuth0 } from '@auth0/auth0-react'
import { envKeys } from 'config'
import { useQuery } from 'react-query'
import { HTTPMethod, HttpRequest } from 'utils/apiUtils'

const fetchProducts = async () => {
  const bearer = `Bearer ${localStorage.getItem('token')}`
  const request: HttpRequest = {
    method: HTTPMethod.GET,
    headers: new Headers({ 'content-type': 'application/json', Authorization: bearer }),
  }
  const response = await fetch(`${envKeys.api.url}/listProductsListings`, request).then((res) => res.json())

  return response
}

const useProductListStatus = ({ enabled = true }: { enabled?: boolean }) => {
  const { user } = useAuth0()

  return useQuery(['listProductsListings'], () => fetchProducts(), {
    enabled: enabled && !!user?.email && !!localStorage.getItem('token'),
    refetchInterval: 2000,
  })
}

export { useProductListStatus }
