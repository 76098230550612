import { CustomText, Flex } from 'components/shared'
// Types
import Button from 'components/shared/Button'
// Hooks
import React from 'react'
import { Colors, FontSize } from 'styles'

interface UploadFilesProps {
  files: any
  setFiles: any
  type: string
}

const UploadFile: React.FC<UploadFilesProps> = ({ files, setFiles, type }) => {
  const fileAccept =
    type === 'excel'
      ? 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      : 'image/png, image/gif, image/jpeg'
  const label = type === 'excel' ? 'Subir lista de productos' : 'Subir Imagen'
  console.log({ fileAccept })

  return (
    <>
      {!files?.name && (
        <>
          <input
            id={'formId' + type}
            type='file'
            accept={fileAccept}
            hidden
            width='100%'
            onChange={(event: any) => {
              setFiles(event?.target?.files[0])
            }}
          />
          <label
            style={{
              minWidth: '197px',
              width: '100%',
              height: '48px',
              background: Colors.violetDark01,
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.09)',
              borderRadius: '4px',
              borderColor: Colors.violetDark01,
              border: `1px solid ${Colors.violetDark01}`,
              color: 'white',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              justifyContent: 'center',
            }}
            htmlFor={'formId' + type}
          >
            {label}
          </label>
        </>
      )}
      {type !== 'excel' && (
        <CustomText fontSize={FontSize.xsmall} style={{ alignSelf: 'flex-start', marginLeft: 4 }}>
          *El tamaño recomendado de la imagen es cuadrado y no puede exceder los 5MB
        </CustomText>
      )}
      {files?.name && (
        <Flex row styles={{ gap: 10 }}>
          <label
            style={{
              width: files ? '100%' : '300px',
              height: '35px',
              background: files ? 'white' : Colors.violetDark01,
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.09)',
              borderRadius: '4px',
              borderColor: Colors.violetDark01,
              border: `1px solid ${Colors.violetDark01}`,
              color: files ? 'black' : 'white',
              margin: 'auto',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              justifyContent: 'center',
            }}
          >
            {files ? files?.name : label}
          </label>
          <Button
            style={{
              backgroundColor: Colors.violetDark01,
              width: '50px',
              textTransform: 'none',
              fontWeight: 'bold',
            }}
            variant='contained'
            onClick={() => setFiles(null)}
            text='Remove'
          />
        </Flex>
      )}
    </>
  )
}

export default UploadFile
