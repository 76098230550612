// Internal components

import ActiveSales from './ActiveSales'
import Admin from './Admin'
import AdminHome from './AdminHome'
import Sales from './EndedSales'
import WalletAdmin from './WalletAdmin'

const paths = {
  empty: '/',
  users: '/users',
  sales: '/sales',
  active: '/active',
  wallet: '/wallet',
}

export const routes = [
  {
    path: paths.empty,
    Page: AdminHome,
    exact: true,
  },
  {
    path: paths.users,
    Page: Admin,
    exact: true,
  },
  {
    path: paths.sales,
    Page: Sales,
    exact: true,
  },
  {
    path: paths.sales + paths.active,
    Page: ActiveSales,
    exact: true,
  },
  {
    path: paths.wallet,
    Page: WalletAdmin,
    exact: true,
  },
]
