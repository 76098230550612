// Material
import { createTheme } from '@mui/material'

// Styles
import { Colors } from './colors'

export enum BackgroundEnum {
  black = 'black',
}

const linearGradientContainedActiveButton = `linear-gradient(118.6deg, ${Colors.violetMain02} 10.57%, ${Colors.violetMain03} 101.85%)`
const linearGradientContainedHoverButton = `linear-gradient(121.69deg, ${Colors.violetLight03} 3.23%, ${Colors.violetLight02} 92.92%)`
const linearGradientContainedButton = `linear-gradient(118.6deg, ${Colors.violetMain01} 10.57%, ${Colors.violetMain02} 101.85%)`

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.blue,
      contrastText: Colors.white,
    },
    secondary: {
      main: Colors.lightGray,
      contrastText: Colors.violetMain,
    },
    background: {
      default: Colors.extraLightgray,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: '12px',
          color: Colors.white,
          textTransform: 'none',

          '&:hover': {
            backgroundImage: ownerState.variant === 'contained' ? linearGradientContainedHoverButton : undefined,
            color: ['outlined', 'text'].includes(ownerState.variant || '') ? Colors.violetLight02 : Colors.white,

            border: ownerState.variant === 'outlined' ? `3px solid ${Colors.violetLight03}` : undefined,
            textDecoration: ownerState.variant === 'text' ? 'underline' : undefined,
          },
          '&:active, &:focus': {
            backgroundImage: ownerState.variant === 'contained' ? linearGradientContainedActiveButton : undefined,

            border: ownerState.variant === 'outlined' ? `3px solid ${Colors.violetMain02}` : undefined,
            textDecoration: ownerState.variant === 'text' ? 'underline' : undefined,
            color: ['contained', 'text'].includes(ownerState.variant || '') ? Colors.violetMain02 : Colors.white,
          },
        }),
        contained: () => ({
          backgroundImage: linearGradientContainedButton,
        }),
        outlined: () => ({
          border: `3px solid ${Colors.violetMain01}`,
          background: 'transparent',
        }),
        text: () => ({
          color: Colors.white,
        }),
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 10,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
        },
        message: {
          padding: 0,
          width: '100%',
        },
      },
    },
  },
})

export default theme
