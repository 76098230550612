import { useCallback, useMemo, useState } from 'react'

interface Helpers {
  currentStep: number
  goToNextStep: () => void
  goToPrevStep: () => void
  reset: () => void
}

function useStepper(maxStep: number): Helpers {
  const [currentStep, setCurrentStep] = useState(0)

  const canGoToNextStep = useMemo(() => currentStep + 1 <= maxStep, [currentStep, maxStep])

  const canGoToPrevStep = useMemo(() => currentStep - 1 >= 0, [currentStep])

  const goToNextStep = useCallback(() => {
    if (canGoToNextStep) {
      setCurrentStep((step) => step + 1)
    }
  }, [canGoToNextStep])

  const goToPrevStep = useCallback(() => {
    if (canGoToPrevStep) {
      setCurrentStep((step) => step - 1)
    }
  }, [canGoToPrevStep])

  const reset = useCallback(() => {
    setCurrentStep(1)
  }, [])

  return {
    currentStep,
    goToNextStep,
    goToPrevStep,
    reset,
  }
}

export default useStepper
