import { yupResolver } from '@hookform/resolvers/yup'
import {
  Alert,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
} from '@mui/material'
import { CustomCard, CustomText, Flex, Loading, Modal, TextField } from 'components/shared'
import ButtonCustom from 'components/shared/Button/Button'
import { format, parseJSON } from 'date-fns'
import useDevice from 'hooks/useDevice'
import {
  BankAccountType,
  ConfirmPaymentType,
  CreditCardType,
  CreditCardTypeLabel,
  PaymentStatusType,
  PaymentType,
  PaymentTypeLabel,
  confirmPayment,
  confirmPaymentSchema,
  useGetCurrentPayment,
} from 'hooks/usePayments'
import { IconCopy, SuccessLottie } from 'images'
import Lottie from 'lottie-react'
import { useState } from 'react'
import { Controller, FieldErrors, useForm } from 'react-hook-form'
import QRCode from 'react-qr-code'
import { useParams } from 'react-router-dom'
import { Colors, FontSize, FontWeight } from 'styles'
import { usdFormatter } from 'utils/formatters'
import getDollarsToDeposit from 'utils/getDollarsToDeposit'
import BaseStyles from './style'

function InfoForm({ paymentKey }: { paymentKey: string }) {
  const [serverError, setServerError] = useState<string | null>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const { control, handleSubmit, formState, watch, getValues } = useForm<ConfirmPaymentType>({
    resolver: yupResolver(confirmPaymentSchema),
  })

  const paymentType = watch('paymentType')

  const isSubmittedSuccessfully = formState.isSubmitted && formState.isSubmitSuccessful

  async function onSubmit(values: ConfirmPaymentType) {
    setServerError(null)
    if (!paymentKey) {
      setServerError('Falta la Key del pago.')

      return
    }

    try {
      await confirmPayment(paymentKey, values)
    } catch (error) {
      setServerError(`
        Algo falló, 
        por favor verifique que este link no haya sido usado anteriormente e 
        intente nuevamente en unos minutos.
      `)

      throw error
    }
  }

  async function onInvalidSubmit(errors: FieldErrors<ConfirmPaymentType>) {
    if (Object.keys(errors).length === 1 && errors.cbuNumber?.message !== 'Requerido') setIsModalOpen(true)
  }

  return (
    <>
      {!isSubmittedSuccessfully && (
        <CustomCard size='big' style={{ padding: 40 }}>
          <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
            <Flex styles={{ gap: 20 }}>
              <CustomText fontSize={FontSize.xxlarge}>Confirmar pago en pesos</CustomText>
              <Controller
                name={'paymentType'}
                control={control}
                render={({ field }) => (
                  <>
                    <FormControl fullWidth error={!!formState.errors.paymentType}>
                      <InputLabel id='payment-option-label'>Pagar</InputLabel>
                      <Select labelId='payment-option-label' label='Pagar' {...field}>
                        <MenuItem value={PaymentType.Deposit}>{PaymentTypeLabel[PaymentType.Deposit]}</MenuItem>
                        <MenuItem value={PaymentType.Transfer}>{PaymentTypeLabel[PaymentType.Transfer]}</MenuItem>
                        <MenuItem value={PaymentType.CreditCard}>{PaymentTypeLabel[PaymentType.CreditCard]}</MenuItem>
                        <MenuItem value={PaymentType.Cash}>{PaymentTypeLabel[PaymentType.Cash]}</MenuItem>
                      </Select>
                      <FormHelperText>{formState.errors.paymentType?.message}</FormHelperText>
                    </FormControl>
                  </>
                )}
              />
              {paymentType && (
                <>
                  {[PaymentType.Deposit, PaymentType.Transfer, PaymentType.CreditCard].includes(paymentType) && (
                    <TextField
                      control={control}
                      name='bankName'
                      textFieldProps={{
                        placeholder: 'Nombre del Banco',
                        fullWidth: true,
                        error: !!formState.errors.bankName,
                        helperText: formState.errors.bankName?.message,
                        label: 'Nombre del Banco *',
                      }}
                    />
                  )}
                  <TextField
                    control={control}
                    name='holderName'
                    textFieldProps={{
                      placeholder: 'Nombre del Titular',
                      fullWidth: true,
                      error: !!formState.errors.holderName,
                      helperText: formState.errors.holderName?.message,
                      label: 'Nombre del Titular *',
                    }}
                  />
                  {[PaymentType.Deposit, PaymentType.Transfer].includes(paymentType) && (
                    <TextField
                      control={control}
                      name='cbuNumber'
                      textFieldProps={{
                        placeholder: 'CBU',
                        fullWidth: true,
                        error: !!formState.errors.cbuNumber,
                        helperText: formState.errors.cbuNumber?.message,
                        label: 'CBU *',
                      }}
                    />
                  )}
                  {[PaymentType.Deposit, PaymentType.Transfer].includes(paymentType) && (
                    <TextField
                      control={control}
                      name='alias'
                      textFieldProps={{
                        placeholder: 'Alias',
                        fullWidth: true,
                        error: !!formState.errors.alias,
                        helperText: formState.errors.alias?.message,
                        label: 'Alias (opcional)',
                      }}
                    />
                  )}
                  {[PaymentType.Deposit, PaymentType.Transfer].includes(paymentType) && (
                    <TextField
                      control={control}
                      name='accountNumber'
                      textFieldProps={{
                        placeholder: 'Número de Cuenta',
                        fullWidth: true,
                        error: !!formState.errors.accountNumber,
                        helperText: formState.errors.accountNumber?.message,
                        label: 'Número de Cuenta (opcional)',
                      }}
                    />
                  )}
                  {[PaymentType.Deposit, PaymentType.Transfer].includes(paymentType) && (
                    <Controller
                      name={'accountType'}
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormControl fullWidth error={!!formState.errors.accountType}>
                            <InputLabel id='account-type-label'>Tipo de Cuenta *</InputLabel>
                            <Select labelId='account-type-label' label='Tipo de Cuenta *' {...field}>
                              <MenuItem value={BankAccountType.Checking}>Cuenta Corriente</MenuItem>
                              <MenuItem value={BankAccountType.Savings}>Caja de ahorro</MenuItem>
                            </Select>
                            <FormHelperText>{formState.errors.accountType?.message}</FormHelperText>
                          </FormControl>
                        </>
                      )}
                    />
                  )}
                  {[PaymentType.Deposit, PaymentType.Transfer, PaymentType.CreditCard].includes(paymentType) && (
                    <TextField
                      control={control}
                      name='holderIdentityNumber'
                      textFieldProps={{
                        placeholder: 'CUIL/CUIT',
                        fullWidth: true,
                        error: !!formState.errors.holderIdentityNumber,
                        helperText: formState.errors.holderIdentityNumber?.message,
                        label: 'CUIL/CUIT *',
                      }}
                    />
                  )}
                  {[PaymentType.CreditCard].includes(paymentType) && (
                    <Controller
                      name={'creditCardType'}
                      control={control}
                      render={({ field }) => (
                        <>
                          <FormControl fullWidth error={!!formState.errors.creditCardType}>
                            <InputLabel id='credit-card-type-label'>Tipo de Tarjeta *</InputLabel>
                            <Select labelId='credit-card-type-label' label='Tipo de Tarjeta *' {...field}>
                              <MenuItem value={CreditCardType.VISA}>
                                {CreditCardTypeLabel[CreditCardType.VISA]}
                              </MenuItem>
                              <MenuItem value={CreditCardType.MC}>{CreditCardTypeLabel[CreditCardType.MC]}</MenuItem>
                              <MenuItem value={CreditCardType.AMEX}>
                                {CreditCardTypeLabel[CreditCardType.AMEX]}
                              </MenuItem>
                            </Select>
                            <FormHelperText>{formState.errors.creditCardType?.message}</FormHelperText>
                          </FormControl>
                        </>
                      )}
                    />
                  )}
                  {[PaymentType.CreditCard].includes(paymentType) && (
                    <TextField
                      control={control}
                      name='creditCardNumber'
                      textFieldProps={{
                        placeholder: 'Número de Tarjeta',
                        fullWidth: true,
                        error: !!formState.errors.creditCardNumber,
                        helperText: formState.errors.creditCardNumber?.message,
                        label: 'Número de Tarjeta *',
                      }}
                    />
                  )}
                  {[PaymentType.Cash].includes(paymentType) && (
                    <TextField
                      control={control}
                      name='deliveryAddress'
                      textFieldProps={{
                        placeholder: 'Dirección de entrega',
                        fullWidth: true,
                        error: !!formState.errors.deliveryAddress,
                        helperText: formState.errors.deliveryAddress?.message,
                        label: 'Dirección de entrega (opcional)',
                      }}
                    />
                  )}
                  <TextField
                    control={control}
                    name='phoneNumber'
                    textFieldProps={{
                      placeholder: 'Teléfono',
                      fullWidth: true,
                      error: !!formState.errors.phoneNumber,
                      helperText: formState.errors.phoneNumber?.message,
                      label: 'Teléfono *',
                    }}
                  />
                  <TextField
                    control={control}
                    name='depositAmount'
                    textFieldProps={{
                      placeholder: 'Monto',
                      fullWidth: true,
                      error: !!formState.errors.depositAmount,
                      helperText: formState.errors.depositAmount?.message,
                      label: 'Monto *',
                    }}
                  />
                </>
              )}
              <Flex row styles={{ gap: 20, flexWrap: 'wrap' }}>
                <ButtonCustom
                  text='Confirmar pago'
                  type='submit'
                  disabled={formState.isSubmitting}
                  isLoading={formState.isSubmitting}
                />
              </Flex>
            </Flex>
          </form>
        </CustomCard>
      )}
      {isSubmittedSuccessfully && <Alert severity='success'>Operación enviada correctamente!</Alert>}
      {serverError && <Alert severity='error'>{serverError}</Alert>}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Flex styles={{ padding: '0 30px 30px 30px', gap: 20, minWidth: 470 }}>
          <CustomText fontSize={FontSize.regular} fontWeight={FontWeight.extraBold}>
            El CBU ingresado no valida
          </CustomText>
          <CustomText fontSize={FontSize.regular}>Compruebe que es correcto, desea continuar?</CustomText>
          <CustomText fontSize={FontSize.regular} fontWeight={FontWeight.bold}>
            {getValues().cbuNumber}
          </CustomText>
          <ButtonCustom
            text='Continuar'
            onClick={() => {
              setIsModalOpen(false)
              onSubmit(getValues())
            }}
          />
        </Flex>
      </Modal>
    </>
  )
}

export default function ConfirmPayment() {
  const { isMobile } = useDevice()

  const { paymentKey } = useParams()

  const { data: currentPayment } = useGetCurrentPayment({ paymentKey })

  if (!currentPayment || !paymentKey) {
    return <Loading />
  }

  const { paymentInfo, registeredCustomer } = currentPayment

  const dollarsToDeposit = paymentInfo?.depositAmount
    ? getDollarsToDeposit(paymentInfo.depositAmount, registeredCustomer.dollarValue)
    : 0

  return (
    <Flex styles={{ gap: 20, padding: isMobile ? '0' : '0 30%' }}>
      {paymentInfo && [PaymentStatusType.Approved, PaymentStatusType.Pending].includes(paymentInfo.status) && (
        <>
          <CustomCard size='medium' style={{ padding: isMobile ? 10 : 40, background: Colors.extraLightgray }}>
            <Flex styles={{ gap: 20 }}>
              <CustomText fontSize={FontSize.small}>
                Fecha: {format(parseJSON(paymentInfo.createdAt), 'dd-MMM-yyyy HH:mm')}
              </CustomText>
              <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                subheader={
                  <ListSubheader component='div' id='nested-list-subheader'>
                    Operación de <strong>{PaymentTypeLabel[paymentInfo.paymentType]}</strong> para:
                  </ListSubheader>
                }
              >
                <ListItem>
                  <ListItemText primary={paymentInfo.holderName} />
                  <ListItemText primary={paymentInfo.bankName} />
                </ListItem>
              </List>
              <CustomText fontSize={FontSize.large} style={{ textAlign: 'center' }}>
                Enviá el <strong style={{ color: Colors.violetDark01 }}>monto exacto en USDT</strong> utilizando{' '}
                <strong style={{ color: Colors.violetDark01 }}>RED POLYGON</strong>, de lo contrario se perderán los
                fondos.
              </CustomText>
              <CustomText fontSize={FontSize.xxxxlarge} style={{ color: Colors.violetDark01, fontWeight: 700 }}>
                {usdFormatter.format(dollarsToDeposit)} USDT
              </CustomText>

              {paymentInfo.status === PaymentStatusType.Approved && (
                <>
                  <QRCode value={registeredCustomer.split} />
                  <Flex row styles={{ gap: 10, flexWrap: 'flex-wrap' }}>
                    <CustomText fontSize={isMobile ? FontSize.small : FontSize.default}>
                      {registeredCustomer.split}
                    </CustomText>
                    <IconButton
                      sx={BaseStyles.iconButton}
                      onClick={() => {
                        navigator.clipboard.writeText(registeredCustomer.split ?? '')
                      }}
                    >
                      <IconCopy />
                    </IconButton>
                  </Flex>
                </>
              )}
              {paymentInfo.status === PaymentStatusType.Pending && (
                <>
                  <CustomText fontSize={FontSize.xlarge}>Pago recibido!</CustomText>
                  <Lottie loop animationData={SuccessLottie} />
                </>
              )}

              <CustomText fontSize={FontSize.large} style={{ textAlign: 'center' }}>
                <strong>IMPORTANTE:</strong>
              </CustomText>
              <ul style={{ maxWidth: 330, margin: 0, padding: 0 }}>
                <li>
                  <CustomText fontSize={FontSize.small}>
                    Una vez realizado el envío correctamente, verás la confirmación de la recepción de los USDT.
                  </CustomText>
                </li>
                <li>
                  <CustomText fontSize={FontSize.small}>
                    <strong>Luego de las 16:00, además, recibirás el comprobante del pago en pesos</strong>.
                  </CustomText>
                </li>
              </ul>
            </Flex>
          </CustomCard>
        </>
      )}

      {!paymentInfo && <InfoForm paymentKey={paymentKey} />}

      {paymentInfo && paymentInfo.status === PaymentStatusType.Confirmed && (
        <Alert severity='success'>Operación enviada correctamente!</Alert>
      )}

      {paymentInfo && paymentInfo.status === PaymentStatusType.Paid && (
        <Alert severity='success'>Operación pagada.</Alert>
      )}

      {paymentInfo && paymentInfo.status === PaymentStatusType.Rejected && (
        <Alert severity='error'>Operación cancelada.</Alert>
      )}
    </Flex>
  )
}
