enum Colors {
  primary = '#2F4260',

  white = '#fff',
  green = '#44EB2D',
  redLigt = '#FF9494',
  blackLight = '#333333',
  orange = '#E74625',
  lightBlue = '#ADD8E6',
  lightYellow = '#FFF04D',
  blue = '#2F4260',

  // Blue
  blueMain = '#2065D1',

  // Violet
  violetLight01 = '#F7D9FE',
  violetLight02 = '#E999FF',
  violetLight03 = '#E37AFF',
  violetMain01 = '#D94CFF',
  violetMain02 = '#B542E4',
  violetMain03 = '#8B34C1',
  violetDark01 = '#6B2AA8',

  violetMain = '#8158FC',
  violetSecondary = '#5626EE',
  violetLigt = '#BAA5FC',
  violetExtraLight = '#E4DBFF',

  // gray
  extraExtraLightgray = '#C4C4C4',
  extraLightgray = '#E5E5E5',
  lightGray = '#EEEEEE',
  darkGray = '#CACACA',
  red = '#FF3C3C',
  black = '#000000',
  greenLight = '#94F464',
  greenBright = '#67F421',
  greyDark = '#212B36 ',
  greyDarker = '#2c2c2c ',
}

export { Colors }
