export enum HTTPMethod {
  POST = 'POST',
  GET = 'GET',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH',
}

export interface HttpRequest {
  headers?: CommonJSON
  body?: string
  method: HTTPMethod
  contentType?: string
}
