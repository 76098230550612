import { Alert, List, ListItem, ListItemText, Snackbar, Tab, Tabs } from '@mui/material'
import { redirect } from 'components/routes/config'
import { Button, CustomCard, CustomText, Loading } from 'components/shared'
import ButtonCustom from 'components/shared/Button/Button'
import { format, parseJSON } from 'date-fns'
import useDevice from 'hooks/useDevice'
import {
  BankAccountTypeLabel,
  CreditCardTypeLabel,
  PaymentInfoApi,
  PaymentStatusType,
  PaymentTypeLabel,
  changePaymentStatus,
  useListPayments,
} from 'hooks/usePayments'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Colors, FontSize } from 'styles'
import { arsFormatter, usdFormatter } from 'utils/formatters'

export default function PaymentsList() {
  const navigate = useNavigate()
  const { isMobile } = useDevice()
  const [filterStatus, setFilterStatus] = useState(PaymentStatusType.Confirmed)

  const [isExecuting, setIsExecuting] = useState(false)

  const [error, setError] = useState<string | null>(null)

  const { data: payments, status, refetch } = useListPayments({ status: filterStatus })

  const handleStatusTabChange = (_: unknown, newValue: PaymentStatusType) => {
    setFilterStatus(newValue)
  }

  const handleChangeStatus = (paymentInfo: PaymentInfoApi) => async () => {
    if (![PaymentStatusType.Confirmed, PaymentStatusType.Pending].includes(paymentInfo.status)) return

    try {
      setIsExecuting(true)

      const newStatus =
        paymentInfo.status === PaymentStatusType.Confirmed ? PaymentStatusType.Approved : PaymentStatusType.Paid

      await changePaymentStatus(paymentInfo.id, newStatus)

      refetch()
    } catch (e: any) {
      console.error(e)

      setError(e?.message ?? 'Ocurrió un error')
    } finally {
      setIsExecuting(false)
    }
  }

  const handleReject = (paymentInfo: PaymentInfoApi) => async () => {
    if (
      ![PaymentStatusType.Confirmed, PaymentStatusType.Approved, PaymentStatusType.Pending].includes(paymentInfo.status)
    )
      return

    try {
      setIsExecuting(true)

      await changePaymentStatus(paymentInfo.id, PaymentStatusType.Rejected)

      refetch()
    } catch (e: any) {
      console.error(e)

      setError(e?.message ?? 'Ocurrió un error')
    } finally {
      setIsExecuting(false)
    }
  }

  return (
    <div style={{ gap: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ display: 'flex', gap: 10 }}>
        <ButtonCustom text='Atrás' onClick={() => navigate(redirect.home())} />
        <ButtonCustom text='Actualizar dólar' onClick={() => navigate(redirect.updateDollar())} />
      </div>
      <CustomCard size='big' style={{ padding: isMobile ? 10 : 40, background: Colors.extraLightgray }}>
        <div style={{ gap: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CustomText fontSize={FontSize.xxlarge}>Lista de Pagos</CustomText>
          <Tabs value={filterStatus} onChange={handleStatusTabChange}>
            <Tab disabled={isExecuting} value={PaymentStatusType.Confirmed} label='Solicitudes' />
            <Tab disabled={isExecuting} value={PaymentStatusType.Approved} label='Cotización enviada' />
            <Tab disabled={isExecuting} value={PaymentStatusType.Pending} label='Pagos pendientes' />
            <Tab disabled={isExecuting} value={PaymentStatusType.Paid} label='Pagados' />
          </Tabs>
          {status === 'loading' && <Loading />}
          {status === 'success' &&
            payments?.map((p) => (
              <List sx={{ width: '100%', bgcolor: 'background.paper' }} key={p.id}>
                <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <ListItemText
                    primary={PaymentTypeLabel[p.paymentType]}
                    secondary={'Tipo de pago'}
                    primaryTypographyProps={{ style: { fontWeight: 700 } }}
                  />
                </ListItem>
                <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <ListItemText
                    primary={p.id}
                    secondary={'ID'}
                    primaryTypographyProps={{ style: { fontWeight: 700 } }}
                  />
                </ListItem>
                <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <ListItemText
                    primary={format(parseJSON(p.createdAt), 'dd-MMM-yyyy HH:mm')}
                    secondary={'Fecha'}
                    primaryTypographyProps={{ style: { fontWeight: 700 } }}
                  />
                </ListItem>
                <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <ListItemText
                    primary={p.holderName}
                    secondary={'Titular'}
                    primaryTypographyProps={{ style: { fontWeight: 700 } }}
                  />
                </ListItem>
                <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <ListItemText
                    primary={p.phoneNumber}
                    secondary={'Teléfono'}
                    primaryTypographyProps={{ style: { fontWeight: 700 } }}
                  />
                </ListItem>
                <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <ListItemText
                    primary={arsFormatter.format(p.depositAmount)}
                    secondary={'Monto'}
                    primaryTypographyProps={{ style: { fontWeight: 700 } }}
                  />
                </ListItem>
                {p.holderIdentityNumber && (
                  <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItemText
                      primary={p.holderIdentityNumber}
                      secondary={'CUIL/CUIT'}
                      primaryTypographyProps={{ style: { fontWeight: 700 } }}
                    />
                  </ListItem>
                )}
                {p.bankName && (
                  <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItemText
                      primary={p.bankName}
                      secondary={'Banco'}
                      primaryTypographyProps={{ style: { fontWeight: 700 } }}
                    />
                  </ListItem>
                )}

                {p.cbuNumber && (
                  <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItemText
                      primary={p.cbuNumber}
                      secondary={'CBU'}
                      primaryTypographyProps={{ style: { fontWeight: 700 } }}
                    />
                  </ListItem>
                )}
                {p.alias && (
                  <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItemText
                      primary={p.alias}
                      secondary={'Alias'}
                      primaryTypographyProps={{ style: { fontWeight: 700 } }}
                    />
                  </ListItem>
                )}
                {p.accountNumber && (
                  <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItemText
                      primary={p.accountNumber}
                      secondary={'Cuenta'}
                      primaryTypographyProps={{ style: { fontWeight: 700 } }}
                    />
                  </ListItem>
                )}
                {p.accountType && (
                  <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItemText
                      primary={BankAccountTypeLabel[p.accountType]}
                      secondary={'Tipo de cuenta'}
                      primaryTypographyProps={{ style: { fontWeight: 700 } }}
                    />
                  </ListItem>
                )}
                {p.creditCardType && (
                  <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItemText
                      primary={CreditCardTypeLabel[p.creditCardType]}
                      secondary={'Tipo de tarjeta'}
                      primaryTypographyProps={{ style: { fontWeight: 700 } }}
                    />
                  </ListItem>
                )}
                {p.creditCardNumber && (
                  <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItemText
                      primary={p.creditCardNumber}
                      secondary={'Número de tarjeta'}
                      primaryTypographyProps={{ style: { fontWeight: 700 } }}
                    />
                  </ListItem>
                )}
                {p.usdtReceived && (
                  <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <ListItemText
                      primary={usdFormatter.format(p.usdtReceived)}
                      secondary={'USDT Recibidos'}
                      primaryTypographyProps={{ style: { fontWeight: 700 } }}
                    />
                  </ListItem>
                )}

                <ListItem style={{ gap: 10 }}>
                  {[PaymentStatusType.Confirmed, PaymentStatusType.Approved].includes(filterStatus) && (
                    <Button
                      style={{ width: '100%', minHeight: 56, background: Colors.red, color: 'white' }}
                      variant='contained'
                      disabled={isExecuting}
                      disableElevation
                      loading={isExecuting}
                      onClick={handleReject(p)}
                      text={'Rechazar'}
                      size={'none'}
                    />
                  )}
                  {[PaymentStatusType.Confirmed, PaymentStatusType.Pending].includes(filterStatus) && (
                    <Button
                      style={{ width: '100%', minHeight: 56 }}
                      color='primary'
                      variant='contained'
                      disabled={isExecuting}
                      disableElevation
                      loading={isExecuting}
                      onClick={handleChangeStatus(p)}
                      text={filterStatus === PaymentStatusType.Confirmed ? 'Cotizado' : 'Pagado'}
                      size={'none'}
                    />
                  )}
                </ListItem>
              </List>
            ))}
        </div>
      </CustomCard>
      {error && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={error ? true : false}
          autoHideDuration={2000}
          onClose={() => setError(null)}
        >
          <Alert
            onClose={() => {
              setError(null)
            }}
            severity='error'
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </div>
  )
}
