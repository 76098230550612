import { ReactNode, useState } from 'react'

// Material
import { Box, styled } from '@mui/system'
import { IconButton } from '@mui/material'
import { DeleteIcon } from 'images'

//Images

//Shared
import { Flex, CustomText, Button, Loading } from 'components/shared'

//Hooks
import useDevice from 'hooks/useDevice'
import useStyles from 'hooks/useStyles'

//Styles
import { FontSize, FontWeight } from 'styles/font'
import getStyles, { boxButtonStyles, boxMobileButtonStyles, buttonsFlexStyles, StyledBox, StyledImg } from './styles'
import { Colors } from 'styles'

export interface NftCardProps {
  loading?: boolean
  title: string
  subtitle?: string | number
  subtitle2?: string | number
  subtitle3?: string | number
  subtitle4?: string | number
  image?: string
  label?: string
  labelTextColor?: Colors
  backgroundLabel?: Colors
  variant?: 'horizontal' | 'vertical'
  onClickIcon?: () => void
  IconLabel?: Icon
  // button
  labelButton?: string
  onClickButton?: () => void
  disabledButton?: boolean
  children?: ReactNode
  disableHover?: boolean
  cardOnclick?: (() => void) | undefined
}

type FlexProps = {
  vertical: number
}

const HoverFlex = styled(Box)<FlexProps>(({ vertical }) =>
  vertical
    ? {
        transition: 'transform .2s',
        '&:hover': {
          transform: 'scale(1.1)',
        },
      }
    : {
        maxWidth: '100%',
      }
)

const ProductCard: React.FC<NftCardProps> = ({
  loading,
  title,
  subtitle,
  subtitle2,
  subtitle3,
  subtitle4,
  label,
  image,
  labelTextColor,
  backgroundLabel = Colors.blackLight,
  variant,
  onClickIcon,
  IconLabel,
  // button
  labelButton,
  disabledButton,
  onClickButton,
  children,
  disableHover = false,
  cardOnclick,
}) => {
  // Hooks
  const { isMobile } = useDevice()
  const [loadedImg, setLoadedImg] = useState(false)

  // Variables
  const isHorizontal = variant ? variant === 'horizontal' : isMobile
  const { BaseStyles } = useStyles({ getStyles: () => getStyles(isHorizontal) })
  const disabledStylesButton = disabledButton ? { background: Colors.extraExtraLightgray, color: Colors.white } : {}
  if (loading) {
    return (
      <HoverFlex style={BaseStyles.hover} vertical={!disableHover ? 1 : 0}>
        <Flex styles={BaseStyles.loading}>
          <Loading />
        </Flex>
      </HoverFlex>
    )
  }

  return (
    <Box
      onClick={() => (cardOnclick ? cardOnclick() : '')}
      sx={{ width: '100%', cursor: cardOnclick ? 'pointer' : '' }}
    >
      <HoverFlex style={BaseStyles.hover} vertical={!disableHover ? 1 : 0}>
        <Flex styles={BaseStyles.root}>
          <Box>
            {label && (
              <StyledBox style={{ color: labelTextColor, background: backgroundLabel }}>
                {IconLabel && <IconLabel />}
                <CustomText fontSize={FontSize.xxsmall} fontWeight={FontWeight.medium}>
                  {label}
                </CustomText>
              </StyledBox>
            )}

            {image && (
              <>
                {!loadedImg && (
                  <Flex>
                    <Loading />
                  </Flex>
                )}
                <StyledImg src={image} alt='productImage' style={BaseStyles.image} onLoad={() => setLoadedImg(true)} />
              </>
            )}
          </Box>

          <Box sx={isHorizontal ? boxMobileButtonStyles : boxButtonStyles}>
            {/* Title */}
            <CustomText style={BaseStyles.text} fontSize={FontSize.large} fontWeight={FontWeight.extraBold}>
              {title}
            </CustomText>

            {/* Subtitle */}
            <CustomText style={BaseStyles.text}>{subtitle}</CustomText>

            {/* Subtitle */}
            <CustomText style={BaseStyles.text}>{subtitle2}</CustomText>
            {/* Subtitle */}
            <CustomText fontWeight={500} style={BaseStyles.text}>
              {subtitle3}
            </CustomText>

            <CustomText>{subtitle4}</CustomText>
            {/* Buttons */}
            <Flex styles={buttonsFlexStyles}>
              {onClickIcon && (
                <IconButton
                  color='primary'
                  sx={{ width: 48, border: '1px solid red', borderRadius: '12px' }}
                  onClick={onClickIcon}
                >
                  <DeleteIcon style={{ fill: 'red' }} />
                </IconButton>
              )}

              {onClickButton && (
                <Button
                  style={{ width: '100%', ...disabledStylesButton }}
                  color='primary'
                  variant='contained'
                  disabled={disabledButton}
                  disableElevation
                  onClick={onClickButton}
                  text={labelButton}
                  size={isHorizontal ? 'none' : 'large'}
                />
              )}

              {children}
            </Flex>
          </Box>
        </Flex>
      </HoverFlex>
    </Box>
  )
}

export default ProductCard
