import { Colors } from 'styles'
import { minHeightTobBar } from 'styles/constants'

const BaseStyles = {
  root: {
    width: '100%',
    minHeight: `calc(100vh - ${minHeightTobBar})`,
    padding: 16,
  },
  rootWithoutLayout: {
    width: '100%',
    padding: 16,
  },
  backgroundDifuminated: {
    background: `linear-gradient(180deg, ${Colors.violetExtraLight}  0%,rgba(255, 255, 255, 0) 66%)`,
  },
  backgroundBlack: {
    background: '#333333',
  },
}

export default BaseStyles
