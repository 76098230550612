import { Colors } from 'styles'
import { minHeightTobBar } from 'styles/constants'

const BaseStyles = {
  root: {
    width: '100%',
    minHeight: `calc(100vh - ${minHeightTobBar})`,
  },
  linkStyles: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  boxStyles: {
    background: Colors.blackLight,
    height: '50vh',
    width: '100vw',
    position: 'absolute',
    bottom: '0%',
    zIndex: 1,
  },
  iconButton: {
    width: 36,
    height: 36,
    backgroundColor: Colors.violetLight03,
    borderRadius: '8px',
    '&:hover': {
      background: Colors.violetDark01,
    },
  },
}

export default BaseStyles
