import { CustomText, Flex } from 'components/shared'
import useDevice from 'hooks/useDevice'
import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
// Styles
import { FontSize, FontWeight } from 'styles'

interface TabMenuItemProps {
  navigateTo: string
  text: string
  onClose?: () => void
}

const TabMenuItem = ({ navigateTo, text, onClose }: TabMenuItemProps) => {
  const { isMobile } = useDevice()
  const navigate = useNavigate()
  const location = useLocation()

  const activeTab = location.pathname === navigateTo || location.pathname.includes(navigateTo)

  const { BaseStyles } = { BaseStyles: { tab: {}, activeTab: {}, label: '' } }

  const rootStyles = React.useMemo(() => {
    const result = BaseStyles.tab
    if (activeTab) {
      return {
        ...result,
        // ...BaseStyles.activeTab,
      }
    }

    return result
  }, [BaseStyles.tab, activeTab])

  return (
    <Link
      to={navigateTo}
      style={{ textDecoration: 'none' }}
      onClick={() => {
        navigate(navigateTo)
        onClose?.()
      }}
    >
      <Flex styles={rootStyles}>
        <CustomText
          fontSize={FontSize.large}
          fontWeight={FontWeight.extraBold}
          //style={BaseStyles.label}
        >
          {text}
        </CustomText>

        {activeTab && !isMobile && (
          <div
            style={{
              background: 'linear-gradient(90deg, #FFFFFF 0%, #67F421 100%)',
              width: '100%',
              height: 6,
              borderRadius: 5,
            }}
          />
        )}
      </Flex>
    </Link>
  )
}
export default TabMenuItem
