// Material
import { AppBar, Container, Toolbar } from '@mui/material'

import Navigation from './navigation/Navigation'
import { topBarStyles } from './styles'

const TopBar = () => {
  return (
    <AppBar position='sticky' elevation={0} sx={topBarStyles}>
      <Container maxWidth='xl'>
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <Navigation />
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default TopBar
