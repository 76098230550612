// Contexts
import 'styles/globalStyles.css'

// Apollo
// Material
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { routes } from 'components/routes/config'
// Components
import Router from 'components/routes/Router/Router'
import { useEffect } from 'react'
// Config
// Hooks
import { BrowserRouter } from 'react-router-dom'
// Integration
import { useAuth0 } from '@auth0/auth0-react'
import { useCostumer } from 'hooks/useCostumer'
import useUser from 'hooks/useUser'
import { useWhitelist } from 'hooks/useWhitelist'

const UserRoutes = () => {
  const { isConnected } = useUser()
  const { isLoading: loadingWhitelist, data: whitelist } = useWhitelist({})
  const whitelistAccess = !loadingWhitelist && whitelist !== null
  const { isLoading: loadingCustomer, data: customerData } = useCostumer({})

  let allRoutes =
    !isConnected || !whitelistAccess || loadingCustomer ? routes.public : [...routes.public, ...routes.private]

  if (!loadingCustomer && customerData?.admin) {
    allRoutes = allRoutes.concat(routes.admin)
  }

  return <Router routes={allRoutes} />
}

const App = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessTokenSilently()
      localStorage.setItem('token', token)
    }
    if (isAuthenticated) {
      getToken()
    }
  }, [getAccessTokenSilently, isAuthenticated])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <BrowserRouter>
        <UserRoutes />
      </BrowserRouter>
    </LocalizationProvider>
  )
}

export default App
