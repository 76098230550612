export const envKeys = {
  //Crypto
  alchemy: {
    id: process.env.REACT_APP_ALCHEMY_ID,
  },
  api: {
    url: process.env.REACT_APP_API_SERVICE_URL ?? 'http://localhost:6060',
  },
  contract: {
    address: process.env.REACT_APP_CONTRACT_ADDRESS,
    wakeUp: process.env.REACT_APP_WAKEUP,
  },
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENTID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  },
  account: {
    pin: process.env.REACT_APP_PIN_CAMBIO_WALLET,
    pinApikey: process.env.REACT_APP_PIN_API_KEY,
  },
}
