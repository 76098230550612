// Components
import ProductCard from 'components/business/ProductCard/ProductCard'
import { redirect } from 'components/routes/config'
import { CustomCard, CustomText, Flex, Loading } from 'components/shared'
import ButtonCustom from 'components/shared/Button/Button'
import { envKeys } from 'config'
import { useAdminProductList } from 'hooks/useAdminProductList'
import { useAdminProductListStatus } from 'hooks/useAdminProductListStatus'
import useDevice from 'hooks/useDevice'
import { ProductStatus } from 'pages/ListAllProducts/SellProduct/SellProduct'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Colors, FontSize, FontWeight } from 'styles'

const EndedSales: React.FC = () => {
  const navigate = useNavigate()
  const { isLoading: loadingProducts, data: productsList } = useAdminProductList({})
  const { isLoading: loadingListing, data: listing } = useAdminProductListStatus({})
  const [finishedProducts, setFinishedProducts] = useState([])
  const { isMobile } = useDevice()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (listing?.length > 0 && productsList?.length > 0) {
      const finishedP = listing.filter(
        (p: Product) => p.status === ProductStatus.sold || p.status === ProductStatus.cancelled
      )
      const finishedProductsArr = finishedP.map((p: any) => {
        const extraInfoP = productsList.find((p2: Product) => p2.id === p.productId)

        return { ...extraInfoP, ...p }
      })
      setFinishedProducts(finishedProductsArr)
    }
  }, [listing, productsList])

  const fetchExportData = async () => {
    setLoading(true)
    const bearer = `Bearer ${localStorage.getItem('token')}`
    const response = await fetch(`${envKeys.api.url}/admin/export/transactions`, {
      method: 'GET',
      headers: new Headers({ Authorization: bearer }),
    })
    if (!response.ok) {
      console.error(`HTTP error! Status: ${response.status}`)
      setLoading(false)

      return
    }
    const blob = await response.blob()
    const downloadUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = downloadUrl

    // Extract filename from the Content-Disposition header
    const contentDisposition = response.headers.get('Content-Disposition')
    const filename = contentDisposition?.split('filename=')[1] ?? 'Transactions.xlsx'

    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    setLoading(false)

    return
  }

  if (loadingProducts || loadingListing || loading) {
    return (
      <Flex styles={{ marginTop: '60px' }}>
        <Loading />
      </Flex>
    )
  }
  const totalSell = finishedProducts.reduce((acc: number, p: any) => (acc += p.price), 0)

  return (
    <Flex styles={{ gap: 20 }}>
      <Flex row={true} styles={{ width: '35%', justifyContent: 'space-between' }}>
        <ButtonCustom text='Atras' onClick={() => navigate(redirect.admin())} />
        <ButtonCustom text='Export Data' onClick={async () => fetchExportData()} />
      </Flex>
      <CustomCard
        size='medium'
        style={{
          padding: isMobile ? 10 : 40,
          background: Colors.extraLightgray,
        }}
      >
        <Flex styles={{ gap: 20 }}>
          {finishedProducts?.length > 0 && (
            <>
              <CustomText fontSize={FontSize.xxlarge} fontWeight={FontWeight.bold}>
                Productos Finalizados
              </CustomText>
              {finishedProducts.map((p: Product & { fee: string }) => (
                <ProductCard
                  title={p.name}
                  subtitle={'Price: ' + p.price + ' USDT' + (p.fee ? ` / Fee: ${p.fee}%` : '')}
                  subtitle2={p.soldDate ? `Vendido el ${new Date(p.soldDate!).toLocaleString()}` : ''}
                  subtitle3={p.explorerLink ? 'Clickea para ver el NFT generado' : ''}
                  subtitle4={'Client: ' + p.email}
                  image={p.uploadedFileUrl}
                  labelButton={p.claimNft ? 'Ver link Claim' : undefined}
                  variant='horizontal'
                  disableHover
                  cardOnclick={p.explorerLink ? () => window.open(p.explorerLink, '_blank') : undefined}
                />
              ))}
              <CustomText fontSize={FontSize.xxlarge}>Total Ventas: {totalSell.toFixed(4)} USDT</CustomText>
            </>
          )}
        </Flex>
      </CustomCard>
    </Flex>
  )
}

export default EndedSales
