export function validateCBULength(cbu: string) {
  if (cbu.length !== 22) {
    return false
  }

  return true
}

export function validateBankCode(code: string) {
  if (code.length !== 8) {
    return false
  }
  const bank = code.substring(0, 3)
  const branch = code.substring(4, 7)

  const verificationDigit1 = Number(code[3])
  const verificationDigit2 = Number(code[7])

  const suma =
    Number(bank[0]) * 7 +
    Number(bank[1]) * 1 +
    Number(bank[2]) * 3 +
    verificationDigit1 * 9 +
    Number(branch[0]) * 7 +
    Number(branch[1]) * 1 +
    Number(branch[2]) * 3

  const diferencia = 10 - (suma % 10)

  return diferencia === verificationDigit2
}

export function validateBankAccount(accountNumber: string) {
  if (accountNumber.length !== 14) {
    return false
  }

  const verificationDigit = Number(accountNumber[13])

  const suma =
    Number(accountNumber[0]) * 3 +
    Number(accountNumber[1]) * 9 +
    Number(accountNumber[2]) * 7 +
    Number(accountNumber[3]) * 1 +
    Number(accountNumber[4]) * 3 +
    Number(accountNumber[5]) * 9 +
    Number(accountNumber[6]) * 7 +
    Number(accountNumber[7]) * 1 +
    Number(accountNumber[8]) * 3 +
    Number(accountNumber[9]) * 9 +
    Number(accountNumber[10]) * 7 +
    Number(accountNumber[11]) * 1 +
    Number(accountNumber[12]) * 3
  const diferencia = 10 - (suma % 10)

  return diferencia === verificationDigit
}

export default function validateCBU(cbu: string) {
  const length = validateCBULength(cbu)
  const bankCode = validateBankCode(cbu.substring(0, 8))
  const bankAccount = validateBankAccount(cbu.substring(8, 22))

  return length && bankCode && bankAccount
}
