// Components
import { Alert, Snackbar } from '@mui/material'
import ProductCard from 'components/business/ProductCard/ProductCard'
import { redirect } from 'components/routes/config'
import { CustomCard, CustomText, Flex, Loading, Modal } from 'components/shared'
import ButtonCustom from 'components/shared/Button/Button'
import { envKeys } from 'config'
import useDevice from 'hooks/useDevice'
import { useProductList } from 'hooks/useProductList'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Colors, FontSize, FontWeight } from 'styles'

const ListAllProducts: React.FC = () => {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [deleteItem, setDeleteItem] = useState<Product>()
  const [productsList, setProductList] = useState<Array<Product>>()
  const { isLoading: loadingProducts, data: productList, refetch: refetchListing } = useProductList({})
  const { isMobile } = useDevice()
  const [, setLoading] = useState(false)
  console.log({ productsList })
  const navigate = useNavigate()

  useEffect(() => {
    if (productList) {
      setProductList(productList.filter((p: Product) => !p.deleted))
    }
  }, [productList])

  const deleteProduct = () => {
    setLoading(true)
    const bearer = `Bearer ${localStorage.getItem('token')}`
    fetch(`${envKeys.api.url}/uploadProduct`, {
      method: 'PUT',
      headers: new Headers({ 'content-type': 'application/json', Authorization: bearer }),
      body: JSON.stringify({ id: deleteItem?.id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.stack?.includes('Error')) {
          throw Error(data.message)
        }
        setSuccess(true)
        refetchListing()
        setModalOpen(false)
      })
      .catch((err) => {
        console.error(err)
        setError(true)
      })
      .finally(() => setLoading(false))
  }

  if (loadingProducts) {
    return (
      <Flex styles={{ marginTop: '60px' }}>
        <Loading />
      </Flex>
    )
  }

  return (
    <Flex styles={{ gap: 20 }}>
      <ButtonCustom text='Atras' onClick={() => navigate(redirect.home())} />
      <CustomCard size='medium' style={{ padding: isMobile ? 10 : 40, background: Colors.extraLightgray }}>
        <Flex styles={{ gap: 20 }}>
          <CustomText fontSize={FontSize.xxlarge}>Lista de Productos</CustomText>
          {productsList?.map((p: Product) => (
            <ProductCard
              title={p.name}
              subtitle={'Price: ' + p.price + ' USDT'}
              image={p.uploadedFileUrl}
              variant='horizontal'
              labelButton='Vender'
              onClickButton={() => navigate(redirect.sellProductId({ productId: p.id }))}
              onClickIcon={() => {
                setDeleteItem(p)
                setModalOpen(true)
              }}
            />
          ))}
          <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
            <Flex styles={{ padding: '0 30px 30px 30px', gap: 20 }}>
              <CustomText fontSize={FontSize.xlarge} fontWeight={FontWeight.extraBold}>
                Elminar Producto {deleteItem?.name}
              </CustomText>
              <CustomText fontSize={FontSize.regular}>
                Este producto no se puede recuperar y si hay listings activos se eliminaran
              </CustomText>
              <Flex row styles={{ gap: 20 }}>
                <ButtonCustom text='Cancelar' onClick={() => setModalOpen(false)} />
                <ButtonCustom text='Eliminar' onClick={() => deleteProduct()} />
              </Flex>
            </Flex>
          </Modal>
        </Flex>
      </CustomCard>
      {success && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={success}
          autoHideDuration={2000}
          onClose={() => setSuccess(false)}
        >
          <Alert
            onClose={() => {
              setSuccess(false)
            }}
            severity='success'
          >
            Producto eliminado!
          </Alert>
        </Snackbar>
      )}
      {error && (
        <Snackbar
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          open={error}
          autoHideDuration={2000}
          onClose={() => setError(false)}
        >
          <Alert
            onClose={() => {
              setError(false)
            }}
            severity='error'
          >
            Algo salio mal, intente nuevamente.
          </Alert>
        </Snackbar>
      )}
    </Flex>
  )
}

export default ListAllProducts
