// Components
import { useState, useEffect } from 'react'
import { redirect } from 'components/routes/config'
import { CustomCard, CustomText, Flex, Loading } from 'components/shared'
import ButtonCustom from 'components/shared/Button/Button'
import { useAdminProductListStatus } from 'hooks/useAdminProductListStatus'
import { useNavigate } from 'react-router-dom'
import { Colors, FontSize, FontWeight } from 'styles'
import { ProductStatus } from 'pages/ListAllProducts/SellProduct/SellProduct'
import ProductCard from 'components/business/ProductCard/ProductCard'
import useDevice from 'hooks/useDevice'
import { useAdminProductList } from 'hooks/useAdminProductList'

const ActiveSales: React.FC = () => {
  const navigate = useNavigate()
  const { isLoading: loadingProducts, data: productsList } = useAdminProductList({})
  const { isLoading: loadingListing, data: listing } = useAdminProductListStatus({})
  const [pendingProducts, setPendingProducts] = useState([])
  const { isMobile } = useDevice()

  useEffect(() => {
    if (listing?.length > 0 && productsList?.length > 0) {
      const pendingP = listing.filter((p: any) => p.status === ProductStatus.onSale)
      const pendingProductsArr = pendingP.map((p: any) => {
        const extraInfoP = productsList.find((p2: Product) => p2.id === p.productId)

        return { ...extraInfoP, ...p }
      })
      setPendingProducts(pendingProductsArr)
    }
  }, [listing, productsList])

  if (loadingProducts || loadingListing) {
    return (
      <Flex styles={{ marginTop: '60px' }}>
        <Loading />
      </Flex>
    )
  }
  const totalPendingSell = pendingProducts.reduce((acc: number, p: any) => (acc += p.price), 0)

  return (
    <Flex styles={{ gap: 20 }}>
      <ButtonCustom text='Atras' onClick={() => navigate(redirect.admin())} />
      <CustomCard size='medium' style={{ padding: isMobile ? 10 : 40, background: Colors.extraLightgray }}>
        <Flex styles={{ gap: 20 }}>
          {pendingProducts?.length > 0 && (
            <>
              <CustomText fontSize={FontSize.xxlarge} fontWeight={FontWeight.bold}>
                Productos en Venta Activos
              </CustomText>
              {pendingProducts.map((p: Product) => (
                <ProductCard
                  title={p.name}
                  subtitle={'Price: ' + p.price + ' USDT'}
                  subtitle2={'Status: ' + p.status}
                  subtitle4={'Client:' + p.email}
                  image={p.uploadedFileUrl}
                  variant='horizontal'
                />
              ))}
            </>
          )}
          <CustomText fontSize={FontSize.large}>Total Ventas Pendientes: {totalPendingSell.toFixed(4)} USDT</CustomText>
        </Flex>
      </CustomCard>
    </Flex>
  )
}

export default ActiveSales
