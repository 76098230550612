// Components
import { redirect } from 'components/routes/config'
import { CustomCard, CustomText, Flex } from 'components/shared'
import ButtonCustom from 'components/shared/Button/Button'
import { useNavigate } from 'react-router-dom'
import { FontSize, FontWeight } from 'styles'

const AdminHome: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Flex styles={{ gap: 20 }}>
      <CustomCard size='medium' style={{ padding: 40 }}>
        <Flex styles={{ gap: 20 }}>
          <CustomText fontSize={FontSize.xxlarge} fontWeight={FontWeight.bold}>
            Panel Admin
          </CustomText>
          <ButtonCustom fullWidth text='Control Ventas Activas' onClick={() => navigate(redirect.adminSalesActive())} />
          <ButtonCustom fullWidth text='Control Ventas Finalizadas' onClick={() => navigate(redirect.adminSales())} />
          <ButtonCustom fullWidth text='Administrar Usuarios' onClick={() => navigate(redirect.adminUsers())} />
          <ButtonCustom fullWidth text='Admin Fee Wallet' onClick={() => navigate(redirect.walletAdminFee())} />
          <ButtonCustom text='Atrás' onClick={() => navigate(redirect.home())} />
        </Flex>
      </CustomCard>
    </Flex>
  )
}

export default AdminHome
