// Layout
import layout from 'components/business/layout'
// Pages
import { Admin } from 'pages/Admin'
import ConfirmPayment from 'pages/Admin/ConfirmPayment'
import InitPayments from 'pages/Admin/InitPayments'
import PaymentsList from 'pages/Admin/PaymentsList'
import UpdateDollar from 'pages/Admin/UpdateDollar'
import Claim from 'pages/Claim'
import ErrorAccess from 'pages/ErrorAccess/ErrorAccess'
import GenerateApiKey from 'pages/GenerateApiKey'
import Home from 'pages/Home'
import { Products } from 'pages/ListAllProducts'
import Login from 'pages/Login'
import ProductForm from 'pages/ProductForm/ProductForm'
import UploadWallet from 'pages/UploadWallet/UploadWallet'

// Pages
export enum BackgroundEnum {
  black = 'black',
}

const simplePaths = {
  // Generics
  empty: '/',
  home: '/',
  login: '/login',
  admin: '/admin',
  users: '/users',
  sales: '/sales',
  active: '/active',
  errorAccess: '/errorAccess',
  claim: '/claim/:claimId',
  pricing: '/pricing',
  wallet: '/wallet',
  product: '/product',
  listProduct: '/listProduct',
  status: '/status',
  productId: '/:productId',
  initPayments: '/init-payments',
  paymentsList: '/payments-list',
  confirmPayment: '/payment/:paymentKey',
  updateDollar: '/update-dollar',
  apiKey: '/api-key',
}

const redirect = {
  empty: () => simplePaths.empty,
  home: () => simplePaths.home,
  claim: () => simplePaths.claim,
  login: () => simplePaths.login,
  admin: () => simplePaths.admin,
  errorAccess: () => simplePaths.errorAccess,
  pricing: () => simplePaths.pricing,
  wallet: () => simplePaths.wallet,
  product: () => simplePaths.product,
  listProduct: () => simplePaths.listProduct,
  listProductsStatus: () => `${simplePaths.listProduct}${simplePaths.status}`,
  listProductsActive: () => `${simplePaths.listProduct}${simplePaths.active}`,
  sellProductId: ({ productId }: CommonJSON<string>) => `${simplePaths.listProduct}/${productId}`,
  adminSales: () => `${simplePaths.admin}${simplePaths.sales}`,
  adminSalesActive: () => `${simplePaths.admin}${simplePaths.sales}${simplePaths.active}`,
  adminUsers: () => `${simplePaths.admin}${simplePaths.users}`,
  walletAdminFee: () => `${simplePaths.admin}${simplePaths.wallet}`,
  initPayments: () => simplePaths.initPayments,
  paymentsList: () => simplePaths.paymentsList,
  confirmPayment: ({ paymentKey }: { paymentKey: string }) =>
    simplePaths.confirmPayment.replace(':paymentKey', paymentKey),
  updateDollar: () => simplePaths.updateDollar,
  apiKey: () => simplePaths.apiKey,
}

const routes: Routes = {
  public: [
    {
      path: simplePaths.home,
      label: 'Home',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.black,
      Page: Home,
      exact: true,
    },
    {
      path: simplePaths.confirmPayment,
      label: 'Confirm Payment',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.black,
      Page: ConfirmPayment,
      exact: true,
    },
    {
      path: simplePaths.login,
      label: 'Login',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.black,
      Page: Login,
      exact: true,
    },
    {
      path: simplePaths.claim,
      label: 'Claim',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.black,
      Page: Claim,
      exact: true,
    },
    {
      path: simplePaths.errorAccess,
      label: 'Error Access',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.black,
      Page: ErrorAccess,
      exact: true,
    },
  ],
  private: [
    {
      path: simplePaths.home,
      label: 'Home',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.black,
      Page: Home,
      exact: true,
    },
    {
      path: simplePaths.wallet,
      label: 'Wallet',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.black,
      Page: UploadWallet,
      exact: true,
    },
    {
      path: simplePaths.product,
      label: 'Product',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.black,
      Page: ProductForm,
      exact: true,
    },
    {
      path: simplePaths.listProduct,
      label: 'List Product',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.black,
      Page: Products,
      exact: false,
    },
    {
      path: simplePaths.initPayments,
      label: 'Init Payments',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.black,
      Page: InitPayments,
      exact: true,
    },
    {
      path: simplePaths.paymentsList,
      label: 'Payments List',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.black,
      Page: PaymentsList,
      exact: true,
    },
    {
      path: simplePaths.updateDollar,
      label: 'Update Dollar',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.black,
      Page: UpdateDollar,
      exact: true,
    },
    {
      path: simplePaths.apiKey,
      label: 'ApiKey',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.black,
      Page: GenerateApiKey,
      exact: true,
    },
  ],
  admin: [
    {
      path: simplePaths.admin,
      label: 'Admin',
      isShownInTab: false,
      Layout: layout,
      Background: BackgroundEnum.black,
      Page: Admin,
      exact: false,
    },
  ],
}

// TODO: make it better with connection state
const tabRoutes = [...routes.public, ...routes.private].filter((route) => route.isShownInTab)

export { redirect, routes, tabRoutes }
