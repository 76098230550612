import { useAuth0 } from '@auth0/auth0-react'
import { envKeys } from 'config'
import { useQuery } from 'react-query'
import { HTTPMethod, HttpRequest } from 'utils/apiUtils'

type CustomerApi = {
  name: string
  email: string
  wallet: string
  split: string
  id: string
  admin?: boolean
  payments?: boolean
  dollarValue?: number
  apiKey?: string
}

const fetchApiKey = async () => {
  const bearer = `Bearer ${localStorage.getItem('token')}`
  const request: HttpRequest = {
    method: HTTPMethod.GET,
    headers: new Headers({ 'content-type': 'application/json', Authorization: bearer }),
  }
  const response = await fetch(`${envKeys.api.url}/customer`, request)

  if (!response.ok) throw new Error('failed to fetch')

  const json = await response.json()

  return json as CustomerApi
}

const useCostumer = ({ enabled = true }: { enabled?: boolean }) => {
  const { user } = useAuth0()

  return useQuery(['customer'], () => fetchApiKey(), {
    enabled: enabled && !!user?.email && !!localStorage.getItem('token'),
  })
}

export { useCostumer }
