// Material
import { Box, ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
// Theme
import theme from 'styles/theme'

// Local components
import TopBar from './TopBar'

interface LayoutProps {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <TopBar />
      <Box>{children}</Box>
    </ThemeProvider>
  )
}

export default Layout
