import { useAuth0 } from '@auth0/auth0-react'
import { envKeys } from 'config'
import { useQuery } from 'react-query'
import { HTTPMethod, HttpRequest } from 'utils/apiUtils'

const fetchWhitelist = async () => {
  const bearer = `Bearer ${localStorage.getItem('token')}`
  const request: HttpRequest = {
    method: HTTPMethod.GET,
    headers: new Headers({ 'content-type': 'application/json', Authorization: bearer }),
  }
  const response = await fetch(`${envKeys.api.url}/admin/allUsers`, request).then((res) => res.json())

  return response
}

const useAllWhitelist = ({ enabled = true }: { enabled?: boolean }) => {
  const { user } = useAuth0()

  return useQuery(['allWhitelist'], () => fetchWhitelist(), {
    enabled: enabled && !!user?.email && !!localStorage.getItem('token'),
  })
}

export { useAllWhitelist }
