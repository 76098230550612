// Styles
import './Navigation.css'

import MenuIcon from '@mui/icons-material/Menu'
import { Drawer, ListItem } from '@mui/material'
import TabMenuItem from 'components/business/TabMenuItem/TabMenuItem'
// Utils
import { redirect, tabRoutes } from 'components/routes/config'
import { Button, CustomText, Flex } from 'components/shared'
// Components
import useDevice from 'hooks/useDevice'
import { useState } from 'react'
// Images
import { useNavigate } from 'react-router-dom'
import { generateKey } from 'utils/react'

import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@mui/system'
import { useCostumer } from 'hooks/useCostumer'
import { FontFamily, FontSize } from 'styles'
import { StyledRoot, TabMenuItemsContainer } from './style'

export default function Navigation() {
  // Hooks
  const navigate = useNavigate()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { isMobile } = useDevice()
  const { isAuthenticated, user, logout } = useAuth0()
  const { data: customerData } = useCostumer({})

  return (
    <StyledRoot>
      <Flex row styles={{ justifyContent: 'space-around', width: '100%' }}>
        <Flex row styles={{ justifyContent: 'space-between', width: '100%' }}>
          {isMobile && (
            <>
              <MenuIcon onClick={() => setDrawerOpen(!drawerOpen)} />
              <Drawer anchor='left' sx={{ width: '30%' }} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                {tabRoutes.map((route) => (
                  <ListItem
                    key={generateKey('menu-item')}
                    sx={{ paddingRight: 6 }}
                    //style={BaseStyles.listItem}
                  >
                    <TabMenuItem
                      navigateTo={route.path!}
                      text={route.label || ''}
                      onClose={() => setDrawerOpen(false)}
                    />
                  </ListItem>
                ))}
              </Drawer>
            </>
          )}
          <CustomText fontSize={FontSize.xxxlarge} fontFamily={FontFamily.third}>
            {' '}
            Basilic{' '}
          </CustomText>
          {isAuthenticated && (
            <>
              <CustomText fontFamily={FontFamily.primary}>{user?.email}</CustomText>
              <Box>
                <Button
                  sx={{ marginLeft: 1 }}
                  onClick={() => {
                    localStorage.setItem('token', '')
                    localStorage.setItem('apiKey', '')
                    logout({ returnTo: window.location.origin })
                  }}
                  text='Logout'
                  color='primary'
                />
                {customerData && customerData.admin && (
                  <Button
                    sx={{ marginLeft: 1 }}
                    onClick={() => navigate(redirect.admin())}
                    text='Admin'
                    color='secondary'
                  />
                )}
              </Box>
            </>
          )}
        </Flex>
      </Flex>
      {!isMobile && (
        <div style={TabMenuItemsContainer}>
          {tabRoutes.map((route) => (
            <TabMenuItem key={generateKey('menu-item-mobile-')} navigateTo={route.path!} text={route.label || ''} />
          ))}
        </div>
      )}
    </StyledRoot>
  )
}
